import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { ILogin } from './types';
import {
  SET_PASSWORD,
  SET_LOADING,
  SET_EMAIL,
  SET_EMAIL_ERROR,
  SET_ERROR_TEXT,
  SET_PASSWORD_ERROR,
} from './actionTypes';

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const errorTextReducer = handleAction<string, string>(
  SET_ERROR_TEXT,
  updateSimpleValue,
  ''
);

const isPasswordReducer = handleAction<boolean, boolean>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  false
);

const isEmailErrorReducer = handleAction<boolean, boolean>(
  SET_EMAIL_ERROR,
  updateSimpleValue,
  false
);

export default combineReducers<ILogin>({
  isLoading: isLoadingReducer,
  email: emailReducer,
  password: passwordReducer,
  isEmailError: isEmailErrorReducer,
  isPasswordError: isPasswordReducer,
  errorText: errorTextReducer,
});
