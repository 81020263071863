export enum ROUTES {
  MAIN = '/',
  ACCOUNT = '/account',
  LOGIN = '/login',
  VERIFY_ACCOUNT = '/verify-account',
  ACTIVATE_LICENSE = '/activate-license',
  REGISTRATION = '/register',
  NOT_FOUND = '/404',
  RESET_PASSWORD = '/reset-password',
  SEND_PASSWORD_RESET_EMAIL = '/password-reset-email',
  FACEBOOK_LOGIN = '/facebook-login',
  GOOGLE_LOGIN = '/google-login',
  PAYMENT_SUCCESS = '/stripe/checkout-success',
}
