import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { updateSimpleValue } from 'utils/reducerHelpers';
import { IAccountVerification } from './types';
import {
  SET_VERIFY_SUCCESS,
  SET_RESEND_SUCCESS,
  SET_INVALID_TOKEN,
  SET_EMAIL,
  SET_VERIFICATION_LOADING,
  SET_RESEND_VISIBILE,
  SET_RESEND_LOADING,
  RESET_STATE,
} from './actionTypes';

const isInvalidTokenReducer = handleAction<boolean, boolean>(
  SET_INVALID_TOKEN,
  updateSimpleValue,
  false
);

const isVerifySuccessReducer = handleAction(
  SET_VERIFY_SUCCESS,
  () => true,
  false
);

const isResendSuccessReducer = handleAction(
  SET_RESEND_SUCCESS,
  () => true,
  false
);

const isVerificationLoadingReducer = handleAction<boolean, boolean>(
  SET_VERIFICATION_LOADING,
  updateSimpleValue,
  false
);

const isResendLoadingReducer = handleAction<boolean, boolean>(
  SET_RESEND_LOADING,
  updateSimpleValue,
  false
);

const isResendVisibleReducer = handleAction<boolean, boolean>(
  SET_RESEND_VISIBILE,
  updateSimpleValue,
  false
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const AccountVerificationReducer = combineReducers<IAccountVerification>({
  isVerificationSuccessful: isVerifySuccessReducer,
  isResendSuccess: isResendSuccessReducer,
  isInvalidToken: isInvalidTokenReducer,
  isVerificationLoading: isVerificationLoadingReducer,
  isResendLoading: isResendLoadingReducer,
  isResendVisible: isResendVisibleReducer,
  email: emailReducer,
});

export default resetReducer(RESET_STATE, AccountVerificationReducer);
