import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Button from 'components/Button';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { userHasSubscription } from 'features/Subscription/selectors';
import { TDispatch } from 'types/common';
import { ROUTES } from 'navigation/constants';

interface IMapDispatchToProps {
  onClick: () => void;
}

type TNoSubscription = IMapDispatchToProps;

const Title = styled(Text)({
  fontSize: 40,
  marginBottom: 40,
});

const LightText = styled.span({
  fontWeight: 100,
});

const StyledButton = styled(Button)({
  maxWidth: 298,
});

const List = styled.ul({
  paddingLeft: 20,
  marginBottom: 40,
});

const ListItem = styled.li((props) => ({
  opacity: 0.8,
  margin: '5px 0',
  '::marker': {
    color: props.theme.colors.electric,
  },
}));

const NoSubscription: React.FC<TNoSubscription> = ({ onClick }) => (
  <>
    <Title>
      Choose Yours App
      <LightText> plan</LightText>
    </Title>
    <Text type="h2">What you will get:</Text>
    <List>
      <ListItem>
        <Text>Content fitting your emotional state</Text>
      </ListItem>
      <ListItem>
        <Text>Sleep stories</Text>
      </ListItem>
      <ListItem>
        <Text>Meditation sessions</Text>
      </ListItem>
      <ListItem>
        <Text>Lo-fi, Ambient, Relaxing music and ASMR tracks</Text>
      </ListItem>
    </List>
    <StyledButton onClick={onClick} text="Choose plan" />
  </>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !userHasSubscription(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(push(ROUTES.MAIN)),
});

export default connect<
  TWithHideableProp<unknown>,
  unknown,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(NoSubscription));
