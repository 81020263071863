import { AxiosResponse } from 'axios';
import { createAction } from 'redux-actions';
import JwtDecode from 'jwt-decode';

import { IRefresh, TLoginResponse } from 'features/Login/types';
import { TThunk } from 'types/common';
import { STORAGE_KEYS } from 'utils/constants';
import { storageGetItem, storageSetItem } from 'utils/helpers';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { IUserInfo } from './types';
import { setSubscriptionData } from 'features/Subscription/actions';
import {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_AUTH_LOADING,
  SET_EMAIL,
  SET_NAME,
  LOGOUT,
} from './actionTypes';

export const setName = createAction<string>(SET_NAME);
export const setEmail = createAction<string>(SET_EMAIL);
export const setAccessToken = createAction<string>(SET_ACCESS_TOKEN);
export const setRefreshToken = createAction<string>(SET_REFRESH_TOKEN);
export const setAuthLoading = createAction<boolean>(SET_AUTH_LOADING);
export const resetStateOnLogout = createAction(LOGOUT);

export const logout = (): TThunk => (dispatch) => {
  dispatch(resetStateOnLogout());

  storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, '');

  dispatch(setAuthLoading(false));
};

export const setAuthData =
  (data: TLoginResponse): TThunk =>
  (dispatch) => {
    const userInfo = JwtDecode<IUserInfo>(data.access);

    if (!userInfo) {
      dispatch(setAuthLoading(false));

      return;
    }

    dispatch(setEmail(userInfo.user.email));
    dispatch(setName(userInfo.user.first_name));
    dispatch(setSubscriptionData(userInfo.user.subscription));

    dispatch(setAccessToken(data.access));
    dispatch(setRefreshToken(data.refresh));
    dispatch(setAuthLoading(false));

    storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, data.refresh);
  };

export const refreshToken = (): TThunk => (dispatch) => {
  const refreshToken = storageGetItem(STORAGE_KEYS.REFRESH_TOKEN);

  if (!refreshToken) {
    dispatch(setAuthLoading(false));

    return;
  }

  refreshTokenRequest(refreshToken)
    .then((response: AxiosResponse<TLoginResponse>) => {
      dispatch(setAuthData(response.data));
    })
    .catch(() => {
      dispatch(logout());
    });
};

export const refreshTokenRequest = (refresh_token: string) =>
  callCoreApi<IRefresh>({
    url: API_URLS.REFRESH_TOKEN,
    data: { refresh: refresh_token },
    method: METHODS.POST,
  });
