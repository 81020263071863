import styled from '@emotion/styled';

import SocialAuthButton from 'components/SocialAuthButton';
import { ReactComponent as FacebookLogo } from 'assets/facebook.svg';
import { ReactComponent as GoogleLogo } from 'assets/google.svg';
import { SOCIAL_AUTH_LINK } from 'features/Login/constants';
import { SOCIAL_SIGNIN_PROVIDER } from 'features/Login/types';

const GoogleButton = styled(SocialAuthButton)({
  marginTop: 12,
});

export enum TEXT_TYPE {
  SIGN_IN = 'Sign in',
  SIGN_UP = 'Sign up',
}

interface ISocialSignIn {
  textType?: TEXT_TYPE;
}

const SocialSignIn: React.FC<ISocialSignIn> = ({
  textType = TEXT_TYPE.SIGN_IN,
}) => (
  <>
    <SocialAuthButton
      href={`${SOCIAL_AUTH_LINK}${SOCIAL_SIGNIN_PROVIDER.FACEBOOK}`}
      icon={<FacebookLogo />}
      text={`${textType} with Facebook`}
    />
    <GoogleButton
      href={`${SOCIAL_AUTH_LINK}${SOCIAL_SIGNIN_PROVIDER.GOOGLE_OAUTH2}`}
      icon={<GoogleLogo />}
      text={`${textType} with Google`}
    />
  </>
);

export default SocialSignIn;
