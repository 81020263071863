import styled from '@emotion/styled';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
});

const Line = styled.hr((props) => ({
  borderBottom: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderTopColor: props.theme.colors.lightGray,
  borderTopWidth: '1px',
  height: '1px',
  flexGrow: 5,
}));

const StyledText = styled.p((props) => ({
  color: props.theme.colors.lightGray,
  textAlign: 'center',
  flexGrow: 1,
}));

interface ISeparator {
  text: string;
}

const Separator: React.FC<ISeparator> = ({ text, ...rest }) => (
  <Container {...rest}>
    <Line />
    <StyledText>{text}</StyledText>
    <Line />
  </Container>
);

export default Separator;
