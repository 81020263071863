import { NAME } from './constants';

export const SET_SUBSCRIPTION_ID = `${NAME}.SET_SUBSCRIPTION_ID`;
export const SET_SUBSCRIPTION_NAME = `${NAME}.SET_SUBSCRIPTION_NAME`;
export const SET_SUBSCRIPTION_STATUS = `${NAME}.SET_SUBSCRIPTION_STATUS`;
export const SET_SUBSCRIPTION_VALID_FROM_DATE = `${NAME}.SET_SUBSCRIPTION_VALID_FROM_DATE`;
export const SET_SUBSCRIPTION_VALID_TO_DATE = `${NAME}.SET_SUBSCRIPTION_VALID_TO_DATE`;
export const SET_AUTO_RENEW_ENABLED = `${NAME}.SET_AUTO_RENEW_ENABLED`;
export const SET_PROVIDER_PRODUCT_ID = `${NAME}.SET_PROVIDER_PRODUCT_ID`;
export const SET_PAYMENT_PROVIDER = `${NAME}.SET_PAYMENT_PROVIDER`;
export const SET_USER_HAS_SUBSCRIPTION = `${NAME}.SET_USER_HAS_SUBSCRIPTION`;
export const SET_AUTO_RENEW_LOADING = `${NAME}.SET_AUTO_RENEW_LOADING`;
export const SET_UPDATE_PAYMENT_DETAILS_LOADING = `${NAME}.SET_UPDATE_PAYMENT_DETAILS_LOADING`;
