import { createAction } from 'redux-actions';
import { AxiosResponse, AxiosError } from 'axios';
import { push } from 'redux-first-history';

import { TThunk } from 'types/common';
import { callCoreApi, API_URLS, METHODS, STATUS_CODES } from 'api';
import { getEmail, getPassword, isLoading } from './selectors';
import { setAuthData } from 'features/Auth/actions';
import { ROUTES } from 'navigation/constants';
import { setLoading as setRegisterLoading } from 'features/Registration/actions';
import { setLoading as setLicenseActivationLoading } from 'features/B2BActivation/actions';
import { ERROR_TEXTS, QUERY_PARAMS } from 'utils/constants';
import { getQueryParam } from 'utils/helpers';
import { COOKIE_KEY, getCookie } from 'utils/cookies';
import {
  IAuthCodeSignInProps,
  ILoginApiProps,
  SOCIAL_SIGNIN_PROVIDER,
  TLoginResponse,
  IMarketingProps,
} from './types';
import {
  SET_EMAIL,
  SET_LOADING,
  SET_PASSWORD,
  SET_EMAIL_ERROR,
  SET_ERROR_TEXT,
  SET_PASSWORD_ERROR,
} from './actionTypes';

export const setEmail = createAction<string>(SET_EMAIL);
export const setPassword = createAction<string>(SET_PASSWORD);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setEmailError = createAction<boolean>(SET_EMAIL_ERROR);
export const setPasswordError = createAction<boolean>(SET_PASSWORD_ERROR);
export const setErrorText = createAction<string>(SET_ERROR_TEXT);

export const handleLogin = (): TThunk => (dispatch, getState) => {
  const state = getState();

  if (isLoading(state)) {
    return;
  }

  dispatch(setErrorText(''));
  dispatch(setEmailError(false));
  dispatch(setPasswordError(false));

  const email = getEmail(state);
  const password = getPassword(state);

  if (!email && !password) {
    dispatch(setEmailError(true));
    dispatch(setPasswordError(true));
    dispatch(setErrorText(ERROR_TEXTS.EMAIL_PASSWORD_REQUIRED));

    return;
  }

  if (!email) {
    dispatch(setEmailError(true));
    dispatch(setErrorText(ERROR_TEXTS.EMAIL_REQUIRED));

    return;
  }

  if (!password) {
    dispatch(setPasswordError(true));
    dispatch(setErrorText(ERROR_TEXTS.PASSWORD_REQUIRED));

    return;
  }

  dispatch(loginRequest(email, password));
};

export const loginRequest =
  (email: string, password: string): TThunk =>
  (dispatch) => {
    dispatch(setLoading(true));

    const fbp = getCookie(COOKIE_KEY._FBP);
    const fbc = getCookie(COOKIE_KEY._FBC);
    const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
    const fbclid = getCookie(COOKIE_KEY.FBCLID);
    const gclid = getCookie(COOKIE_KEY.GCLID);
    const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID);
    const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID);
    const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN);
    const source = getCookie(COOKIE_KEY.UTM_SOURCE);
    const medium = getCookie(COOKIE_KEY.UTM_MEDIUM);
    const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID);
    let google_cid;

    if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
      google_cid = window.ga?.getAll()?.[0]?.get('clientId');
    }

    callCoreApi<ILoginApiProps & IMarketingProps>({
      // url: API_URLS.LOGIN,
      url: '/auth/login/token/' as typeof API_URLS.LOGIN,
      // method: METHODS.POST,
      method: 'post' as typeof METHODS.POST,
      data: {
        username: email,
        password,
        google_cid,
        appsflyer_web_id,
        fbc,
        fbp,
        fbclid,
        gclid,
        tune_transaction_id,
        tune_offer_id,
        campaign,
        source,
        medium,
        impact_click_id,
      },
    })
      .then((response: AxiosResponse<TLoginResponse>) => {
        dispatch(setAuthData(response.data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === STATUS_CODES.UNAUTHORIZED) {
          dispatch(setErrorText(ERROR_TEXTS.INVALID_CREDENTIALS));
          dispatch(setEmailError(true));
          dispatch(setPasswordError(true));
        } else {
          dispatch(setErrorText(ERROR_TEXTS.SOMETHING_WENT_WRONG));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setRegisterLoading(false));
        dispatch(setLicenseActivationLoading(false));
      });
  };

export const onSocialSignIn =
  (provider: SOCIAL_SIGNIN_PROVIDER): TThunk =>
  (dispatch, getState) => {
    const state = getState();
    const authCode = getQueryParam(state, QUERY_PARAMS.CODE);

    if (!authCode) {
      dispatch(push(ROUTES.LOGIN));
      return;
    }

    authCodeSignIn(authCode, provider)
      .then((response: AxiosResponse<TLoginResponse>) => {
        dispatch(setAuthData(response.data));
      })
      .catch(() => {
        dispatch(push(ROUTES.LOGIN));
      });
  };

const authCodeSignIn = (authCode: string, provider: SOCIAL_SIGNIN_PROVIDER) => {
  const fbp = getCookie(COOKIE_KEY._FBP);
  const fbc = getCookie(COOKIE_KEY._FBC);
  const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
  const fbclid = getCookie(COOKIE_KEY.FBCLID);
  const gclid = getCookie(COOKIE_KEY.GCLID);
  const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID);
  const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID);
  const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN);
  const source = getCookie(COOKIE_KEY.UTM_SOURCE);
  const medium = getCookie(COOKIE_KEY.UTM_MEDIUM);
  const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID);
  let google_cid;

  if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
    google_cid = window.ga?.getAll()?.[0]?.get('clientId');
  }

  return callCoreApi<IAuthCodeSignInProps & IMarketingProps>({
    method: METHODS.POST,
    url: API_URLS.AUTH_CODE_SIGNIN,
    data: {
      provider,
      code: authCode,
      google_cid,
      appsflyer_web_id,
      platform: 'web',
      fbp,
      fbc,
      gclid,
      fbclid,
      tune_offer_id,
      tune_transaction_id,
      campaign,
      source,
      medium,
      impact_click_id,
    },
  });
};
