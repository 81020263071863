import { getSearchParams } from 'navigation/selectors';
import { IReduxState } from 'state/types';
import { QUERY_PARAMS, STORAGE_KEYS } from './constants';

export const getQueryParam = (state: IReduxState, key: QUERY_PARAMS) =>
  new URLSearchParams(getSearchParams(state)).get(key);

export const storageSetItem = (key: STORAGE_KEYS, value: string) => {
  localStorage.setItem(key, value);
};

export const storageRemoveItem = (key: STORAGE_KEYS) => {
  localStorage.removeItem(key);
};

export const storageGetItem = (key: STORAGE_KEYS) => {
  return localStorage.getItem(key);
};

export const numberBetween = (number: number, from: number, to: number) =>
  number >= from && number <= to;

export const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

export const isPasswordValid = (password: string) =>
  password.length > 8 &&
  password.toLocaleLowerCase() !== password &&
  /\d/.test(password);
