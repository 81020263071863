import { createSelector } from 'reselect';

import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getName = (state: IReduxState) => state?.[NAME]?.name;

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const getAccessToken = (state: IReduxState) =>
  state?.[NAME]?.accessToken;

export const getRefreshToken = (state: IReduxState) =>
  state?.[NAME]?.refreshToken;

export const isAuthLoading = (state: IReduxState) =>
  state?.[NAME]?.isAuthLoading;

export const isAuthenticated = createSelector(
  getAccessToken,
  getRefreshToken,
  (accessToken: string, refreshToken: string) => !!accessToken && !!refreshToken
);
