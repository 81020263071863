export const NAME = 'accountDetails';

export enum ACCOUNT_PAGES {
  DETAILS = '/account-details',
  SUBSCRIPTION = '/subscription',
}

export enum NAVIGATION_BUTTONS {
  DETAILS = 'Account details',
  SUBSCRIPTION = 'Subscription',
}
