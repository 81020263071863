import { KeyboardEventHandler, useCallback } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import Link from 'components/Link';
import Text from 'components/Text';
import Separator from 'components/Separator';
import SocialSignIn, { TEXT_TYPE } from 'features/SocialSignIn/components';
import LegalText from 'components/LegalText';
import FullScreenWrapper from 'components/FullScreenWrapper';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { IReduxState } from 'state/types';
import { ROUTES } from 'navigation/constants';
import {
  getEmail,
  getPassword,
  isLoading,
  getFirstName,
  getFirstNameError,
  getEmailError,
  getPasswordError,
  getCommonError,
} from '../selectors';
import {
  setEmail,
  setPassword,
  handleRegistration,
  setFirstName,
} from '../actions';

interface IMapStateToProps {
  email: string;
  password: string;
  firstName: string;
  firstNameError: string;
  emailError: string;
  passwordError: string;
  commonError: string;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onEmailChange: (value: string) => void;
  onFirstNameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: () => void;
}

type TRegistration = IMapStateToProps & IMapDispatchToProps;

const Container = styled.form({
  maxWidth: '364px',
  padding: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const RegistrationNote = styled(Text)({
  marginTop: '40px',
  marginBottom: '16px',
});

const StyledButton = styled(Button)({
  marginTop: '32px',
});

const HelperNote = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
  marginRight: '5px',
}));

const HelperContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '16px',
  marginBottom: '16px',
});

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
}));

const Registration: React.FC<TRegistration> = ({
  email,
  firstName,
  password,
  isButtonDisabled,
  firstNameError,
  emailError,
  passwordError,
  commonError,
  onEmailChange,
  onPasswordChange,
  onFirstNameChange,
  onSubmit,
}) => {
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (!isButtonDisabled) onSubmit();
      }
    },
    [isButtonDisabled, onSubmit]
  );
  return (
    <FullScreenWrapper>
      <Container>
        <Logo />
        <RegistrationNote role="title" type="h2">
          Create an account
        </RegistrationNote>
        {!!commonError && <Error>{commonError}</Error>}
        <Input
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={onFirstNameChange}
          onKeyDown={handleKeyDown}
          error={firstNameError}
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={onEmailChange}
          onKeyDown={handleKeyDown}
          error={emailError}
        />
        <Input
          placeholder="Password"
          value={password}
          onChange={onPasswordChange}
          onKeyDown={handleKeyDown}
          type="password"
          error={passwordError}
        />
        <StyledButton
          onClick={onSubmit}
          text="Sign up"
          isDisabled={isButtonDisabled}
        />
        <Separator text="OR" />
        <SocialSignIn textType={TEXT_TYPE.SIGN_UP} />
        <HelperContainer>
          <HelperNote>Do you have an account?</HelperNote>
          <Link to={ROUTES.LOGIN}>Sign in</Link>
        </HelperContainer>
        <LegalText />
      </Container>
    </FullScreenWrapper>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  email: getEmail(state),
  firstName: getFirstName(state),
  password: getPassword(state),
  isButtonDisabled: isLoading(state),
  firstNameError: getFirstNameError(state),
  emailError: getEmailError(state),
  commonError: getCommonError(state),
  passwordError: getPasswordError(state),
});

const mapDispatchToProps = {
  onEmailChange: setEmail,
  onPasswordChange: setPassword,
  onFirstNameChange: setFirstName,
  onSubmit: handleRegistration,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
