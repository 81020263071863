import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory, History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';

import { isDev } from 'utils/constants';
import createRootReducer from './reducers';
import { IReduxState } from './types';

export const makeStore = ({
  preloadedState,
  preloadedHistory,
}: {
  preloadedState?: IReduxState;
  preloadedHistory?: History;
} = {}) => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: preloadedHistory || createBrowserHistory(),
    });

  const middleware = applyMiddleware(routerMiddleware, thunk);

  const middlewareWrapper = isDev
    ? composeWithDevTools(middleware)
    : compose(middleware);

  const store = preloadedState
    ? createStore(
        createRootReducer(routerReducer),
        preloadedState,
        middlewareWrapper
      )
    : createStore(createRootReducer(routerReducer), middlewareWrapper);

  const history = createReduxHistory(store);

  return { store, history: history };
};

const { store, history } = makeStore();

export { store as default, history };
