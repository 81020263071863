import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as Logo } from 'assets/logo.svg';
import FullScreenWrapper from 'components/FullScreenWrapper';
import LegalText from 'components/LegalText';
import Input from 'components/Input';
import Text from 'components/Text';
import Button from 'components/Button';
import { IReduxState } from 'state/types';

import { setFirstName, setPassword, handleActivation } from '../actions';
import {
  getFirstName,
  getPassword,
  getFirstNameError,
  getPasswordError,
  isLoading,
} from '../selectors';

interface IMapStateToProps {
  firstName: string;
  password: string;
  firstNameError: string;
  passwordError: string;
  isButtonLoading: boolean;
}

interface IMapDispatchToProps {
  onFirstNameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: () => void;
}

const Container = styled.form({
  maxWidth: '364px',
  padding: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled(Text)({
  marginTop: '40px',
  marginBottom: '16px',
});

const StyledButton = styled(Button)({
  marginTop: '32px',
});

type TB2BActivation = IMapStateToProps & IMapDispatchToProps;

const B2BActivation: React.FC<TB2BActivation> = ({
  firstName,
  firstNameError,
  password,
  passwordError,
  isButtonLoading,
  onFirstNameChange,
  onPasswordChange,
  onSubmit,
}) => (
  <FullScreenWrapper>
    <Container>
      <Logo />
      <Title type="h2">Activate Yours license</Title>
      <Input
        placeholder="First name"
        value={firstName}
        error={firstNameError}
        onChange={onFirstNameChange}
      />
      <Input
        placeholder="Password"
        value={password}
        error={passwordError}
        onChange={onPasswordChange}
        type="password"
      />
      <StyledButton
        onClick={onSubmit}
        text="Activate"
        isDisabled={isButtonLoading}
      />
      <LegalText />
    </Container>
  </FullScreenWrapper>
);

const mapStateToProps = (state: IReduxState) => ({
  firstName: getFirstName(state),
  password: getPassword(state),
  firstNameError: getFirstNameError(state),
  passwordError: getPasswordError(state),
  isButtonLoading: isLoading(state),
});

const mapDispatchToProps = {
  onFirstNameChange: setFirstName,
  onPasswordChange: setPassword,
  onSubmit: handleActivation,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(B2BActivation);
