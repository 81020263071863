import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { IReduxState } from 'state/types';
import { isAuthenticated } from 'features/Auth/selectors';
import { ROUTES } from './constants';

interface IMapStateToProps {
  isAuthenticated: boolean;
}

type TAuthenticatedRoute = IMapStateToProps;

const AuthenticatedRoute: React.FC<TAuthenticatedRoute> = ({
  isAuthenticated,
}) => {
  const location = useLocation();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isAuthenticated: isAuthenticated(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(AuthenticatedRoute);
