import { connect } from 'react-redux';
import { TDispatch } from 'types/common';

import { IReduxState } from 'state/types';
import SocialSignInScreen, { ISocialLoginHandler } from './SocialSignInScreen';
import { onSocialSignIn } from '../actions';
import { SOCIAL_SIGNIN_PROVIDER } from '../types';

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onMount: () => dispatch(onSocialSignIn(SOCIAL_SIGNIN_PROVIDER.FACEBOOK)),
});

export default connect<unknown, ISocialLoginHandler, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(SocialSignInScreen);
