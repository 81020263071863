import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getPlansUids = (state: IReduxState) => state?.[NAME]?.plansUids;

export const getPlanNameByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.plansByUid?.[uid]?.product.name;

export const getPlanDescriptionByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.plansByUid?.[uid]?.product.description;

export const getPlanMonthlyPriceByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.plansByUid?.[uid]?.product.price_monthly;

export const getPlanDiscountByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.plansByUid?.[uid]?.product.discount_percent;

export const getSelectedPlan = (state: IReduxState) =>
  state?.[NAME]?.selectedPlan;

export const isAutoPurchaseLoading = (state: IReduxState) =>
  state?.[NAME]?.isAutoPurchaseLoading;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;
