import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { getEmail, getName } from 'features/Auth/selectors';
import { setNewPassword, setOldPassword, changePassword } from '../actions';
import {
  getNewPassword,
  getNewPasswordError,
  getOldPassword,
  getOldPasswordError,
  isPasswordChangeLoading,
} from '../selectors';

interface IMapStateToProps {
  newPassword: string;
  oldPassword: string;
  newPasswordError: string;
  oldPasswordError: string;
  name: string;
  email: string;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onNewPasswordChange: (val: string) => void;
  onOldPasswordChange: (val: string) => void;
  onClick: () => void;
}

type TAccountDetails = IMapStateToProps & IMapDispatchToProps;

const Container = styled.form({
  width: '100%',
  maxWidth: 418,
});

const StyledButton = styled(Button)({
  maxWidth: 298,
  marginTop: 45,
});

const Title = styled(Text)({
  marginBottom: 16,
});

const PageTitle = styled(Text)({
  fontSize: 40,
  marginBottom: 40,
});

const LightText = styled.span({
  fontWeight: 100,
});

const InfoLabel = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
}));

const InfoContainer = styled.div({
  marginBottom: 54,
});

const Info = styled.div({
  marginTop: 24,
});

const Dash = styled.span((props) => ({
  width: 24,
  opacity: 0.2,
  marginLeft: 12,
  marginBottom: 4,
  display: 'inline-block',
  borderTop: `1px solid ${props.theme.colors.white}`,
}));

const AccountDetails: React.FC<TAccountDetails> = ({
  onNewPasswordChange,
  onOldPasswordChange,
  onClick,
  newPassword,
  newPasswordError,
  oldPassword,
  oldPasswordError,
  isButtonDisabled,
  name,
  email,
}) => (
  <Container>
    <PageTitle>
      Account
      <LightText> details</LightText>
    </PageTitle>
    <InfoContainer>
      <Title>
        MAIN INFORMATION
        <Dash />
      </Title>
      <Info>
        <InfoLabel>First name</InfoLabel>
        <Text>{name}</Text>
      </Info>
      <Info>
        <InfoLabel>Email</InfoLabel>
        <Text>{email}</Text>
      </Info>
    </InfoContainer>
    <Title>
      CHANGE PASSWORD
      <Dash />
    </Title>
    <Input
      placeholder="Old password"
      onChange={onOldPasswordChange}
      value={oldPassword}
      error={oldPasswordError}
      type="password"
    />
    <Input
      placeholder="New password"
      onChange={onNewPasswordChange}
      value={newPassword}
      error={newPasswordError}
      type="password"
    />
    <StyledButton
      onClick={onClick}
      text="Change password"
      isDisabled={isButtonDisabled}
    />
  </Container>
);

const mapStateToProps = (state: IReduxState) => {
  const newPassword = getNewPassword(state);
  const oldPassword = getOldPassword(state);

  return {
    newPassword,
    oldPassword,
    newPasswordError: getNewPasswordError(state),
    oldPasswordError: getOldPasswordError(state),
    email: getEmail(state),
    name: getName(state),
    isButtonDisabled:
      isPasswordChangeLoading(state) || !oldPassword || !newPassword,
  };
};

const mapDispatchToProps = {
  onNewPasswordChange: setNewPassword,
  onOldPasswordChange: setOldPassword,
  onClick: changePassword,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);
