import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { IPasswordReset } from './types';
import resetReducer from 'utils/resetReducer';
import {
  SET_PASSWORD,
  SET_LOADING,
  SET_SUCCESS,
  SET_TOKEN,
  SET_FORM_VISIBILITY,
  SET_PASSWORD_ERROR,
  RESET_STATE,
} from './actionTypes';

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const tokenReducer = handleAction<string, string>(
  SET_TOKEN,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const isSuccessReducer = handleAction<boolean, boolean>(
  SET_SUCCESS,
  updateSimpleValue,
  false
);

const formVisibilityReducer = handleAction<boolean, boolean>(
  SET_FORM_VISIBILITY,
  updateSimpleValue,
  false
);

const passwordErrorReducer = handleAction<string, string>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const passwordResetReducers = combineReducers<IPasswordReset>({
  password: passwordReducer,
  token: tokenReducer,
  isLoading: isLoadingReducer,
  isSuccess: isSuccessReducer,
  passwordError: passwordErrorReducer,
  isFormVisible: formVisibilityReducer,
});

export default resetReducer(RESET_STATE, passwordResetReducers);
