import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const getFirstName = (state: IReduxState) => state?.[NAME]?.firstName;

export const getPassword = (state: IReduxState) => state?.[NAME]?.password;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getFirstNameError = (state: IReduxState) =>
  state?.[NAME]?.firstNameError;

export const getEmailError = (state: IReduxState) => state?.[NAME]?.emailError;

export const getCommonError = (state: IReduxState) =>
  state?.[NAME]?.commonError;

export const getPasswordError = (state: IReduxState) =>
  state?.[NAME]?.passwordError;
