import { connect } from 'react-redux';

import hideable, { TWithHideableProp } from 'utils/hideable';
import Input from 'components/Input';
import { IReduxState } from 'state/types';
import { getEmail, isResendVisible, isResendLoading } from '../selectors';
import { setEmail, handleResend } from '../actions';

interface IMainMapStateToProps {
  value: string;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onChange: (value: string) => void;
  onClick: () => void;
}

type TEmailResend = IMainMapStateToProps & IMapDispatchToProps;

const EmailResend: React.FC<TEmailResend> = ({
  onChange,
  onClick,
  value,
  isButtonDisabled,
}) => (
  <div>
    <Input onChange={onChange} value={value} />
    <button onClick={onClick} disabled={isButtonDisabled}>
      Submit
    </button>
  </div>
);

const mapStateToProps = (state: IReduxState) => ({
  value: getEmail(state),
  isButtonDisabled: isResendLoading(state),
  isVisible: isResendVisible(state),
});

const mapDispatchToProps = {
  onChange: setEmail,
  onClick: handleResend,
};

export default connect<
  TWithHideableProp<IMainMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable<TEmailResend>(EmailResend));
