import FullScreenWrapper from 'components/FullScreenWrapper';
import React, { useEffect } from 'react';

export interface ISocialLoginHandler {
  onMount: () => void;
}

const SocialSignInScreen: React.FC<ISocialLoginHandler> = ({ onMount }) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return <FullScreenWrapper />;
};

export default SocialSignInScreen;
