import hideable from 'utils/hideable';

import Text from 'components/Text';
import FullScreenWrapper from 'components/FullScreenWrapper';

const LoadingScreen: React.FC = () => (
  <FullScreenWrapper>
    <Text type="h2">loading...</Text>
  </FullScreenWrapper>
);

export default hideable(LoadingScreen);
