import { createAction } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { callCoreApi, METHODS, API_URLS } from 'api';
import { isPasswordValid, getQueryParam } from 'utils/helpers';
import { TThunk } from 'types/common';
import { ERROR_TEXTS, QUERY_PARAMS } from 'utils/constants';
import { IUser } from 'features/Auth/types';
import { loginRequest } from 'features/Login/actions';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { COOKIE_KEY, getCookie } from 'utils/cookies';

import { IB2BActivationApiProps } from './types';
import { getFirstName, getPassword } from './selectors';
import {
  SET_PASSWORD,
  SET_PASSWORD_ERROR,
  SET_FIRST_NAME,
  SET_FIRST_NAME_ERROR,
  SET_LOADING,
  RESET_STATE,
} from './actionTypes';

export const resetState = createAction(RESET_STATE);
export const setPassword = createAction<string>(SET_PASSWORD);
export const setPasswordError = createAction<string>(SET_PASSWORD_ERROR);
export const setFirstName = createAction<string>(SET_FIRST_NAME);
export const setFirstNameError = createAction<string>(SET_FIRST_NAME_ERROR);
export const setLoading = createAction<boolean>(SET_LOADING);

export const handleActivation = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const password = getPassword(state);
  const firstName = getFirstName(state);
  const licenseId = getQueryParam(state, QUERY_PARAMS.LICENSE_ID);

  dispatch(setFirstNameError(''));
  dispatch(setPasswordError(''));

  if (!firstName) {
    dispatch(setFirstNameError(ERROR_TEXTS.BLANK_FIELD));

    return;
  }

  if (!password) {
    dispatch(setPasswordError(ERROR_TEXTS.BLANK_FIELD));

    return;
  }

  const fbp = getCookie(COOKIE_KEY._FBP);
  const fbc = getCookie(COOKIE_KEY._FBC);
  const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
  let google_cid;

  if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
    google_cid = window.ga?.getAll()?.[0]?.get('clientId');
  }

  dispatch(setPasswordError(''));

  if (!isPasswordValid(password)) {
    dispatch(setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR));

    return;
  }

  dispatch(setLoading(true));

  callCoreApi<IB2BActivationApiProps>({
    method: METHODS.POST,
    url: API_URLS.MANAGE_B2B_LICENSE,
    queryParams: `${licenseId}/activate/`,
    data: {
      password,
      first_name: firstName,
      google_cid,
      appsflyer_web_id,
      platform: 'web',
      fbp,
      fbc,
    },
  })
    .then((response: AxiosResponse<IUser>) => {
      dispatch(loginRequest(response?.data?.email, password));
    })
    .catch((error: AxiosError) => {
      if (
        error.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
      ) {
        const passwordError = error.response?.data?.errors?.password?.[0] || '';
        const firstNameError =
          error.response?.data?.errors?.fist_name?.[0] || '';

        dispatch(setFirstNameError(firstNameError));
        dispatch(setPasswordError(passwordError));
      } else {
        toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      }

      dispatch(setLoading(false));
    });
};
