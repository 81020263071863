import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import store from 'state/store';
import { ERROR_TEXTS } from 'utils/constants';
import { getAccessToken, getRefreshToken } from 'features/Auth/selectors';
import { TLoginResponse } from 'features/Login/types';
import { CORE_API, API_URLS, METHODS, CUSTOM_ERROR_CODES } from './constants';
import {
  logout,
  refreshTokenRequest,
  setAuthData,
} from 'features/Auth/actions';

interface ICallApiProps<T> {
  method: METHODS;
  url: API_URLS;
  data?: T;
  queryParams?: string;
  authorized?: boolean;
}

axios.interceptors.response.use(
  (value: AxiosResponse<any>) => value,
  async (error: AxiosError) => {
    if (
      error?.config?.url === `${CORE_API}${API_URLS.REFRESH_TOKEN}` ||
      error?.response?.data?.custom_status_code !==
        CUSTOM_ERROR_CODES.STATUS_INVALID_TOKEN
    ) {
      return Promise.reject(error);
    }

    try {
      const refreshToken = getRefreshToken(store.getState());
      const response: AxiosResponse<TLoginResponse> = await refreshTokenRequest(
        refreshToken
      );
      const accessToken = response?.data?.access;
      error.config.headers.Authorization = `Bearer ${accessToken}`;

      store.dispatch<any>(setAuthData(response.data));

      return axios.request(error.config);
    } catch {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      store.dispatch<any>(logout());
    }
  }
);

const callCoreApi = <T>({
  method,
  url,
  data,
  queryParams,
  authorized,
}: ICallApiProps<T>) => {
  const state = store.getState();
  const accessToken = getAccessToken(state);

  return axios({
    method,
    url: `${CORE_API}${url}${queryParams || ''}`,
    data,
    headers: authorized
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });
};

export default callCoreApi;
