import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Text from 'components/Text';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { IReduxState } from 'state/types';
import { isSuccess, getEmail } from '../selectors';

interface IMapStateToProps {
  email: string;
}

type TSendEmailSuccess = IMapStateToProps;

const Title = styled(Text)({
  marginTop: '86px',
  marginBottom: '16px',
});

const StyledText = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
}));

const Email = styled.span((props) => ({
  color: props.theme.colors.white,
}));

const SendEmailSuccess: React.FC<TSendEmailSuccess> = ({ email }) => (
  <>
    <Title type="h2">Please verify your email.</Title>
    <StyledText align="center">
      We have sent an email to <Email>{email}</Email>.
    </StyledText>
  </>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: isSuccess(state),
  email: getEmail(state),
});

export default connect<
  TWithHideableProp<IMapStateToProps>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable<TSendEmailSuccess>(SendEmailSuccess));
