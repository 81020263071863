import { createAction } from 'redux-actions';
import { AxiosError } from 'axios';
import { push } from 'redux-first-history';

import { callCoreApi, METHODS, API_URLS } from 'api';
import { ROUTES } from 'navigation/constants';
import { isPasswordValid, getQueryParam } from 'utils/helpers';
import { TThunk } from 'types/common';
import { getPassword, getToken } from './selectors';
import { IPasswordResetApiProps, IVerifyTokenProps } from './types';
import { ERROR_TEXTS, QUERY_PARAMS } from 'utils/constants';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { setResendFlow } from 'features/SendPasswordResetEmail/actions';
import {
  SET_PASSWORD,
  SET_LOADING,
  SET_SUCCESS,
  SET_TOKEN,
  SET_FORM_VISIBILITY,
  RESET_STATE,
  SET_PASSWORD_ERROR,
} from './actionTypes';

export const resetState = createAction(RESET_STATE);
export const setPassword = createAction<string>(SET_PASSWORD);
export const setToken = createAction<string>(SET_TOKEN);
export const setPasswordError = createAction<string>(SET_PASSWORD_ERROR);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setSuccess = createAction<boolean>(SET_SUCCESS);
export const setFormVisibility = createAction<boolean>(SET_FORM_VISIBILITY);

export const resetPassword = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const password = getPassword(state);
  const token = getToken(state);

  dispatch(setPasswordError(''));

  if (!isPasswordValid(password)) {
    dispatch(setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR));

    return;
  }

  dispatch(setLoading(true));

  callCoreApi<IPasswordResetApiProps>({
    method: METHODS.POST,
    url: API_URLS.RESET_PASSWORD,
    data: { password, token },
  })
    .then(() => {
      dispatch(setSuccess(true));
      dispatch(setFormVisibility(false));
    })
    .catch((error: AxiosError) => {
      if (
        error?.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_RESET_PASSWORD_TOKEN_IS_EXPIRED
      ) {
        dispatch(setResendFlow(true));
        dispatch(push(ROUTES.SEND_PASSWORD_RESET_EMAIL));
      } else {
        dispatch(setPasswordError(ERROR_TEXTS.SOMETHING_WENT_WRONG));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const verifyToken = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const token = getQueryParam(state, QUERY_PARAMS.TOKEN);

  if (!token) {
    dispatch(push(ROUTES.LOGIN));
    return;
  }

  dispatch(setLoading(true));

  callCoreApi<IVerifyTokenProps>({
    method: METHODS.POST,
    url: API_URLS.VERIFY_PASSWORD_RESET_TOKEN,
    data: { token },
  })
    .then(() => {
      dispatch(setFormVisibility(true));
      dispatch(setToken(token));
    })
    .catch((error: AxiosError) => {
      if (
        error?.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_RESET_PASSWORD_TOKEN_IS_EXPIRED
      ) {
        dispatch(setResendFlow(true));
        dispatch(push(ROUTES.SEND_PASSWORD_RESET_EMAIL));
      } else {
        dispatch(push(ROUTES.LOGIN));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
