import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const isSuccess = (state: IReduxState) => state?.[NAME]?.isSuccess;

export const getEmailError = (state: IReduxState) => state?.[NAME]?.emailError;

export const isResendFlow = (state: IReduxState) => state?.[NAME]?.isResendFlow;
