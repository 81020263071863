import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as Logo } from 'assets/logo.svg';
import FullScreenWrapper from 'components/FullScreenWrapper';
import { IReduxState } from 'state/types';
import PasswordResetForm from './PasswordResetForm';
import PasswordResetSuccess from './PasswordResetSuccess';
import { resetState, verifyToken } from '../actions';

interface IPasswordReset {
  onMount: () => void;
  onUnmount: () => void;
}

const Container = styled.form({
  maxWidth: '364px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
});

const PasswordReset: React.FC<IPasswordReset> = ({ onMount, onUnmount }) => {
  useEffect(() => {
    onMount();

    return onUnmount;
  }, [onMount, onUnmount]);

  return (
    <FullScreenWrapper>
      <Container>
        <Logo />
        <PasswordResetForm />
        <PasswordResetSuccess />
      </Container>
    </FullScreenWrapper>
  );
};

const mapDispatchToProps = {
  onMount: verifyToken,
  onUnmount: resetState,
};

export default connect<unknown, IPasswordReset, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(PasswordReset);
