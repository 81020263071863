export interface ISubscriptionData {
  id: string;
  provider_product: {
    id: string;
    product: {
      id: string;
      name: string;
      description: string;
      price: string;
    };
    provider: {
      id: string;
      name: PAYMENT_PROVIDERS;
    };
    external_id: string;
    metadata: null;
  };
  status: TSubscriptionStatus;
  external_id: string;
  period_start: string;
  period_end: string;
  in_grace_period: string;
  auto_renew: boolean;
}

export type TSubscriptionStatus = 'active' | 'expired' | 'refunded' | 'created';

export interface ISubscription {
  subscriptionId: string;
  providerProductId: string;
  name: string;
  validFrom: string;
  validTo: string;
  isAutoRenewEnabled: boolean;
  userHasSubscription: boolean | null;
  paymentProvider: PAYMENT_PROVIDERS | null;
  isAutoRenewLoading: boolean;
  isUpdatePaymentDetailsLoading: boolean;
  subscriptionStatus: TSubscriptionStatus | null;
}

export enum PAYMENT_PROVIDERS {
  APPLE = 'Apple',
  GOOGLE = 'Google',
  STRIPE = 'Stripe',
  PAYPAL = 'Paypal',
}

export interface IAutoRenewBody {
  cancel_at_period_end: boolean;
}
