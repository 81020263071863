import { toast } from 'react-toastify';

import { APP_QUERY_PARAMS_ACTIONS } from './constants';

import { TThunk } from 'types/common';
import { refreshToken, setAuthLoading } from 'features/Auth/actions';
import { QUERY_PARAMS } from 'utils/constants';
import { getQueryParam } from 'utils/helpers';
import { COOKIE_KEY, setCookie } from 'utils/cookies';

export const handleAppMount = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const webSelectedPlan = getQueryParam(state, QUERY_PARAMS.WEB_PLAN_ID);
  const couponCode = getQueryParam(state, QUERY_PARAMS.COUPON);
  const licenseId = getQueryParam(state, QUERY_PARAMS.LICENSE_ID);
  const tuneOfferId = getQueryParam(state, QUERY_PARAMS.TUNE_OFFER_ID);
  const utmCampaign = getQueryParam(state, QUERY_PARAMS.UTM_CAMPAIGN);
  const utmMedium = getQueryParam(state, QUERY_PARAMS.UTM_MEDIUM);
  const utmSource = getQueryParam(state, QUERY_PARAMS.UTM_SOURCE);
  const action = getQueryParam(state, QUERY_PARAMS.ACTION);
  const impactClickId = getQueryParam(state, QUERY_PARAMS.IMPACT_CLICK_ID);
  const tuneTransactionId = getQueryParam(
    state,
    QUERY_PARAMS.TUNE_TRANSACTION_ID
  );

  dispatch(setAuthLoading(true));

  if (!!webSelectedPlan) {
    setCookie(COOKIE_KEY.WEB_PLAN_ID, webSelectedPlan);
  }

  if (!!couponCode) {
    setCookie(COOKIE_KEY.COUPON, couponCode);
  }

  if (!!impactClickId) {
    setCookie(COOKIE_KEY.IMPACT_CLICK_ID, impactClickId);
  }

  if (!!tuneOfferId) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, tuneOfferId);
  }

  if (!!tuneTransactionId) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, tuneTransactionId);
  }

  if (!!utmCampaign) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, utmCampaign);
  }

  if (!!utmMedium) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, utmMedium);
  }

  if (!!utmSource) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, utmSource);
  }

  if (action === APP_QUERY_PARAMS_ACTIONS.PAYMENT_METHOD_UPDATED) {
    toast.success('Your payment details has been successfully updated.');
  }

  if (!!licenseId) {
    dispatch(setAuthLoading(false));

    return;
  }

  dispatch(refreshToken());
};
