import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { IReduxState } from 'state/types';

import PlanComponent from './PlanComponent';
import Button from 'components/Button';
import Text from 'components/Text';
import { userHasSubscription } from 'features/Subscription/selectors';
import { fetchPlans, handlePlanPurchase } from '../actions';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { getPlansUids, isLoading } from '../selectors';

interface IMapStateToProps {
  uids: string[];
  isPurchaseLoading: boolean;
}

interface IMapDispatchToProps {
  onMount: () => void;
  onClick: () => void;
}

type TPlans = IMapDispatchToProps & IMapStateToProps;

const PlansContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const StyledButton = styled(Button)({
  maxWidth: 300,
  marginBottom: 120,
  marginTop: 40,
});

const Bold = styled.span({
  fontWeight: 'bold',
});

const Title = styled(Text)({
  fontSize: 40,
  marginTop: 92,
  marginBottom: 80,
  fontWeight: 200,
});

const Plans: React.FC<TPlans> = ({
  onMount,
  uids,
  isPurchaseLoading,
  onClick,
}) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <Title>
        <Bold>Try Yours </Bold>7 days for free
      </Title>
      <PlansContainer>
        {uids.map((uid: string) => (
          <PlanComponent key={uid} uid={uid} />
        ))}
      </PlansContainer>
      <StyledButton
        buttonType="secondary"
        text="Subscribe"
        isDisabled={isPurchaseLoading}
        onClick={onClick}
      />
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !userHasSubscription(state),
  uids: getPlansUids(state),
  isPurchaseLoading: isLoading(state),
});

const mapDispatchToProps = {
  onMount: fetchPlans,
  onClick: handlePlanPurchase,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(Plans));
