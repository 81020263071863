import { connect } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { IReduxState } from 'state/types';
import { isAuthenticated } from 'features/Auth/selectors';
import { ROUTES } from 'navigation/constants';

interface ILocationState {
  from?: {
    pathname?: string;
  };
}

interface IMapStateToProps {
  isAuthenticated: boolean;
}
type TUnauthenticatedRoute = IMapStateToProps;

const UnauthenticatedRoute: React.FC<TUnauthenticatedRoute> = ({
  isAuthenticated,
}) => {
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const mainRedirectDestination = locationState?.from?.pathname || ROUTES.MAIN;

  return isAuthenticated ? (
    <Navigate to={mainRedirectDestination} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isAuthenticated: isAuthenticated(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(UnauthenticatedRoute);
