import ActiveSubscription from './ActiveSubscription';
import NoSubscription from './NoSubscription';

const Subscription: React.FC = () => (
  <>
    <ActiveSubscription />
    <NoSubscription />
  </>
);

export default Subscription;
