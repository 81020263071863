import React, { useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import { ReactComponent as Visible } from 'assets/password_visible.svg';
import { ReactComponent as Invisible } from 'assets/password_invisible.svg';

type TInputType = 'password' | 'number' | 'email' | 'text';

interface IInput {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  type?: TInputType;
  error?: string;
  className?: string;
}

interface IErrorProp {
  error?: string;
}

type TInput = IInput &
  IErrorProp &
  Omit<JSX.IntrinsicElements['input'], keyof IInput>;

const Container = styled.div<IErrorProp>((props) => ({
  borderBottom: `1px solid ${
    !!props.error ? props.theme.colors.error : 'rgba(139, 146, 157, 0.25)'
  }`,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

const Wrapper = styled.div({
  width: '100%',
  marginTop: '8px',
  marginBottom: '8px',
});

const Label = styled.p((props) => ({
  color: props.theme.colors.lightGray,
  fontSize: '10px',
  margin: 0,
  height: '16px',
}));

const InputContainer = styled.div({
  width: '100%',
});

const StyledInput = styled.input((props) => ({
  border: 'none',
  background: 'transparent',
  fontSize: '15px',
  marginBottom: '8px',
  outline: 'none',
  width: '100%',
  color: props.theme.colors.white,
  '::placeholder': {
    color: props.theme.colors.lightGray,
  },
}));

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
}));

const TogglePassword = styled.button({
  border: 'none',
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: 8,
});

const Input: React.FC<TInput> = ({
  onChange,
  value,
  type,
  placeholder,
  error,
  className,
  ...rest
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  const onInputFocus = () => {
    setInputFocused(true);
  };

  const onInputBlur = () => {
    setInputFocused(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onPasswordToggleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setPasswordVisibility(!isPasswordVisible);
  };

  const isPassword = type === 'password';

  const inputType = isPassword && isPasswordVisible ? 'text' : type;

  return (
    <Wrapper className={className}>
      <Container error={error}>
        <InputContainer>
          <Label>{(isInputFocused || value) && placeholder}</Label>
          <StyledInput
            onChange={handleChange}
            value={value}
            type={inputType}
            placeholder={isInputFocused ? '' : placeholder}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            {...rest}
          />
        </InputContainer>
        {isPassword && (
          <TogglePassword onClick={onPasswordToggleClick}>
            {isPasswordVisible ? <Visible /> : <Invisible />}
          </TogglePassword>
        )}
      </Container>
      {!!error && (
        <Error
          data-testid={`${placeholder
            ?.toLowerCase()
            .split(' ')
            .join('-')}-error`}
          type="small"
        >
          {error}
        </Error>
      )}
    </Wrapper>
  );
};

export default Input;
