import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { ISubscription, PAYMENT_PROVIDERS, TSubscriptionStatus } from './types';
import {
  SET_SUBSCRIPTION_VALID_TO_DATE,
  SET_SUBSCRIPTION_NAME,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  SET_PAYMENT_PROVIDER,
  SET_AUTO_RENEW_ENABLED,
  SET_USER_HAS_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
  SET_PROVIDER_PRODUCT_ID,
  SET_AUTO_RENEW_LOADING,
  SET_UPDATE_PAYMENT_DETAILS_LOADING,
} from './actionTypes';

const validToReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_VALID_TO_DATE,
  updateSimpleValue,
  ''
);

const subscriptionStatusReducer = handleAction<
  TSubscriptionStatus | null,
  TSubscriptionStatus | null
>(SET_SUBSCRIPTION_STATUS, updateSimpleValue, null);

const validFromReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  updateSimpleValue,
  ''
);

const nameReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_NAME,
  updateSimpleValue,
  ''
);

const providerProductIdReducer = handleAction<string, string>(
  SET_PROVIDER_PRODUCT_ID,
  updateSimpleValue,
  ''
);

const subscriptionIdReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_ID,
  updateSimpleValue,
  ''
);

const isAutoRenewEnabledReducer = handleAction<boolean, boolean>(
  SET_AUTO_RENEW_ENABLED,
  updateSimpleValue,
  false
);

const userHasSubscriptionReducer = handleAction<boolean | null, boolean | null>(
  SET_USER_HAS_SUBSCRIPTION,
  updateSimpleValue,
  null
);

const paymentProviderReducer = handleAction<
  PAYMENT_PROVIDERS | null,
  PAYMENT_PROVIDERS
>(SET_PAYMENT_PROVIDER, updateSimpleValue, null);

const isAutoRenewLoadingReducer = handleAction<boolean, boolean>(
  SET_AUTO_RENEW_LOADING,
  updateSimpleValue,
  false
);

const isUpdatePaymentDetailsLoadingReducer = handleAction<boolean, boolean>(
  SET_UPDATE_PAYMENT_DETAILS_LOADING,
  updateSimpleValue,
  false
);

export default combineReducers<ISubscription>({
  name: nameReducer,
  validFrom: validFromReducer,
  validTo: validToReducer,
  paymentProvider: paymentProviderReducer,
  isAutoRenewEnabled: isAutoRenewEnabledReducer,
  subscriptionId: subscriptionIdReducer,
  userHasSubscription: userHasSubscriptionReducer,
  providerProductId: providerProductIdReducer,
  isAutoRenewLoading: isAutoRenewLoadingReducer,
  subscriptionStatus: subscriptionStatusReducer,
  isUpdatePaymentDetailsLoading: isUpdatePaymentDetailsLoadingReducer,
});
