import styled from '@emotion/styled';

import lake from 'assets/lake.jpg';
import Header from 'features/Header/components';
import Purchase from 'features/Purchase/components';
import Text from 'components/Text';
import { BREAKPOINTS } from 'theme/constants';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignSelf: 'stretch',
  minHeight: '100vh',
});

const Hero = styled.div({
  height: 500,
  backgroundImage: `url(${lake})`,
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [BREAKPOINTS.HERO_IMAGE]: {
    backgroundSize: 'auto 100%',
  },
});

const Title = styled(Text)({
  fontSize: 40,
  letterSpacing: 4,
  fontWeight: 600,
  lineHeight: '70px',
});

const Subtitle = styled(Title)({
  fontSize: 40,
  letterSpacing: 4,
  fontWeight: 100,
  fontStyle: 'italic',
  lineHeight: '50px',
});

const Home: React.FC = () => (
  <Container>
    <Header />
    <Hero>
      <Title role="title">MINDFULNESS</Title>
      <Subtitle>made easy...</Subtitle>
    </Hero>
    <Purchase />
  </Container>
);

export default Home;
