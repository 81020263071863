export const CORE_API = process.env.REACT_APP_CORE_API;

export const STATUS_CODES = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  HANDLED_ERROR: 400,
};

export const CUSTOM_ERROR_CODES = {
  STATUS_DEFAULT: 1000,
  STATUS_VALIDATION_ERROR: 1001,
  STATUS_AUTH_FAILED: 1002,
  STATUS_INVALID_TOKEN: 1003,
  STATUS_ACCOUNT_IS_NOT_VERIFIED: 1004,
  STATUS_VERIFICATION_TOKEN_IS_EXPIRED: 1005,
  STATUS_ACCOUNT_IS_ALREADY_VERIFIED: 1006,
  STATUS_RESET_PASSWORD_TOKEN_IS_EXPIRED: 1007,
};

export enum METHODS {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export enum API_URLS {
  LOGIN = '/auth/login/token/',
  REFRESH_TOKEN = '/auth/login/token/refresh/',
  VERIFY_TOKEN = '/auth/login/token/verify/',
  AUTH_CODE_SIGNIN = '/auth/social/complete/',
  REGISTER = '/users/register/',
  VERIFY_ACCOUNT = '/users/verify/',
  SEND_PASSWORD_RESET_EMAIL = '/users/reset-password/',
  CHANGE_PASSWORD = '/users/change-password/',
  RESET_PASSWORD = '/users/reset-password/confirm/',
  VERIFY_PASSWORD_RESET_TOKEN = '/users/reset-password/token/verify/',
  RESEND_VERIFICATION_TOKEN = '/users/verification-token/',
  PLANS = '/payments/provider-products/',
  CANCEL_PAYPAL_SUBSCRIPTION = '/payments/paypal/subscriptions/',
  STRIPE_SESSION = '/payments/stripe/session/',
  MANAGE_SUB = '/payments/stripe/subscriptions/',
  MANAGE_B2B_LICENSE = '/business/licenses/',
}
