import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { IAuth } from './types';
import {
  SET_REFRESH_TOKEN,
  SET_ACCESS_TOKEN,
  SET_AUTH_LOADING,
  SET_EMAIL,
  SET_NAME,
} from './actionTypes';

const nameReducer = handleAction<string, string>(
  SET_NAME,
  updateSimpleValue,
  ''
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const accessTokenReducer = handleAction<string, string>(
  SET_ACCESS_TOKEN,
  updateSimpleValue,
  ''
);

const refreshTokenReducer = handleAction<string, string>(
  SET_REFRESH_TOKEN,
  updateSimpleValue,
  ''
);

const isAuthLoadingReducer = handleAction<boolean, boolean>(
  SET_AUTH_LOADING,
  updateSimpleValue,
  true
);

export default combineReducers<IAuth>({
  accessToken: accessTokenReducer,
  refreshToken: refreshTokenReducer,
  isAuthLoading: isAuthLoadingReducer,
  name: nameReducer,
  email: emailReducer,
});
