import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { IRegistration } from './types';
import {
  SET_PASSWORD,
  SET_LOADING,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_FIRST_NAME_ERROR,
  SET_PASSWORD_ERROR,
  SET_EMAIL_ERROR,
  SET_COMMON_ERROR,
} from './actionTypes';

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const firstNameReducer = handleAction<string, string>(
  SET_FIRST_NAME,
  updateSimpleValue,
  ''
);

const firstNameErrorReducer = handleAction<string, string>(
  SET_FIRST_NAME_ERROR,
  updateSimpleValue,
  ''
);

const emailErrorReducer = handleAction<string, string>(
  SET_EMAIL_ERROR,
  updateSimpleValue,
  ''
);

const passwordErrorReducer = handleAction<string, string>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const commonErrorReducer = handleAction<string, string>(
  SET_COMMON_ERROR,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

export default combineReducers<IRegistration>({
  isLoading: isLoadingReducer,
  firstName: firstNameReducer,
  email: emailReducer,
  password: passwordReducer,
  emailError: emailErrorReducer,
  passwordError: passwordErrorReducer,
  firstNameError: firstNameErrorReducer,
  commonError: commonErrorReducer,
});
