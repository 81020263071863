import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as GooglePlayIcon } from 'assets/google_play.svg';
import { ReactComponent as AppStoreIcon } from 'assets/apple_store.svg';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { userHasSubscription } from 'features/Subscription/selectors';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { EXTERNAL_LINKS } from 'utils/constants';

const Container = styled.div({});

const Bold = styled.span({
  fontWeight: 'bold',
});

const Title = styled(Text)({
  fontSize: 40,
  marginTop: 92,
  marginBottom: 16,
  fontWeight: 200,
  textAlign: 'center',
});

const Description = styled(Text)({
  opacity: 0.8,
  textAlign: 'center',
});

const Links = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: 100,
  marginTop: 28,
});

const LinkContainer = styled.a({
  height: 64,
  width: 216,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 40,
  margin: '20px',
  textDecoration: 'none',
});

const AppStoreLink = styled(LinkContainer)((props) => ({
  color: props.theme.colors.black,
  backgroundColor: props.theme.colors.white,
}));

const GooglePlayLink = styled(LinkContainer)((props) => ({
  color: props.theme.colors.white,
  backgroundColor: props.theme.colors.black,
}));

const TextContainer = styled.div({
  marginLeft: 16,
});

const SmallText = styled.div({
  fontSize: 12,
});

const BigText = styled.div({
  fontSize: 16,
  fontWeight: 'bold',
});

const AppLinks: React.FC = () => (
  <Container>
    <Title>
      <Bold>Download </Bold>
      Yours app
    </Title>
    <Description type="h2">
      Our content is available on Yours Mobile App
    </Description>
    <Links>
      <AppStoreLink href={EXTERNAL_LINKS.APP_STORE} target="_blank">
        <AppStoreIcon />
        <TextContainer>
          <SmallText>Available on</SmallText>
          <BigText>App store</BigText>
        </TextContainer>
      </AppStoreLink>
      <GooglePlayLink href={EXTERNAL_LINKS.GOOGLE_PLAY} target="_blank">
        <GooglePlayIcon />
        <TextContainer>
          <SmallText>Available on</SmallText>
          <BigText>Google play</BigText>
        </TextContainer>
      </GooglePlayLink>
    </Links>
  </Container>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !!userHasSubscription(state),
});

export default connect<
  TWithHideableProp<unknown>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(AppLinks));
