import { createSelector } from 'reselect';

import { isAuthLoading } from 'features/Auth/selectors';
import { isAutoPurchaseLoading } from 'features/Purchase/selectors';

export const isAppLoading = createSelector(
  isAuthLoading,
  isAutoPurchaseLoading,
  (authLoading: boolean, autoPurchaseLoading: boolean) =>
    authLoading || autoPurchaseLoading
);
