import styled from '@emotion/styled';

import NavigationButton from './NavigationButton';
import { BREAKPOINTS } from 'theme/constants';
import { NAVIGATION_BUTTONS, ACCOUNT_PAGES } from '../constants';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.menuBlue,
  width: '272px',
  [BREAKPOINTS.XXL_MOBILE]: {
    width: '100%',
  },
}));

const NavButtons = styled.div({
  padding: '64px 60px',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 0,
  [BREAKPOINTS.XXL_MOBILE]: {
    padding: '10px 40px',
  },
});

const Navigation: React.FC = () => (
  <Container>
    <NavButtons>
      <NavigationButton
        route={ACCOUNT_PAGES.DETAILS}
        text={NAVIGATION_BUTTONS.DETAILS}
      />
      <NavigationButton
        route={ACCOUNT_PAGES.SUBSCRIPTION}
        text={NAVIGATION_BUTTONS.SUBSCRIPTION}
      />
    </NavButtons>
  </Container>
);

export default Navigation;
