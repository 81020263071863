export interface ILogin {
  email: string;
  password: string;
  isLoading: boolean;
  isEmailError: boolean;
  isPasswordError: boolean;
  errorText: string;
}

export interface IMarketingProps {
  google_cid: string;
  appsflyer_web_id: string;
  fbp: string;
  fbc: string;
  fbclid: string;
  gclid: string;
  tune_offer_id: string;
  tune_transaction_id: string;
  source: string;
  medium: string;
  campaign: string;
  impact_click_id: string;
}

export interface ILoginApiProps {
  username: string;
  password: string;
}

export enum SOCIAL_SIGNIN_PROVIDER {
  GOOGLE_OAUTH2 = 'google-oauth2',
  FACEBOOK = 'facebook',
}

export interface IAuthCodeSignInProps {
  provider: SOCIAL_SIGNIN_PROVIDER;
  code: string;
  platform: 'web';
}

export interface IRefresh {
  refresh: string;
}

export interface IAccess {
  access: string;
}

export type TLoginResponse = IRefresh & IAccess;
