import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { ROUTES } from 'navigation/constants';
import { setEmail, sendEmail } from '../actions';
import {
  isLoading,
  getEmail,
  isSuccess,
  getEmailError,
  isResendFlow,
} from '../selectors';

interface IMapStateToProps {
  isButtonDisabled: boolean;
  email: string;
  emailError: string;
  buttonText: string;
  title: string;
  note: string;
  shouldShowLoginLink: boolean;
}

interface IMapDispatchToProps {
  onClick: () => void;
  onBackClick: () => void;
  onChange: (value: string) => void;
}

type TResetPassword = IMapStateToProps & IMapDispatchToProps;

const StyledText = styled(Text)({
  marginTop: '40px',
  marginBottom: '16px',
});

const StyledNote = styled(Text)({
  opacity: '80%',
});

const StyledInput = styled(Input)({
  marginTop: '32px',
  marginBottom: '32px',
});

const SubmitButton = styled(Button)({
  marginBottom: '12px',
});

const ResetPassword: React.FC<TResetPassword> = ({
  isButtonDisabled,
  email,
  emailError,
  buttonText,
  title,
  note,
  shouldShowLoginLink,
  onChange,
  onClick,
  onBackClick,
}) => (
  <>
    <StyledText type="h2">{title}</StyledText>
    <StyledNote align="center">{note}</StyledNote>
    <StyledInput
      error={emailError}
      placeholder="Email"
      value={email}
      onChange={onChange}
      type="email"
    />
    <SubmitButton
      text={buttonText}
      disabled={isButtonDisabled}
      onClick={onClick}
    />
    {shouldShowLoginLink && (
      <Button
        buttonType="secondary"
        text="Back to sign in"
        disabled={isButtonDisabled}
        onClick={onBackClick}
      />
    )}
  </>
);

const mapStateToProps = (state: IReduxState) => {
  const isResend = isResendFlow(state);

  return {
    isButtonDisabled: isLoading(state),
    email: getEmail(state),
    emailError: getEmailError(state),
    isVisible: !isSuccess(state),
    shouldShowLoginLink: !isResend,
    buttonText: isResend ? 'Resend' : 'Send email',
    title: isResend ? 'Your link is expired.' : 'Reset password',
    note: isResend
      ? 'Please enter your email address and we’ll send you another verification link.'
      : 'We will send you a password reset link.',
  };
};

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onChange: (value: string) => dispatch(setEmail(value)),
  onClick: () => dispatch(sendEmail()),
  onBackClick: () => dispatch(push(ROUTES.LOGIN)),
});

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable<TResetPassword>(ResetPassword));
