import { equals, omit, reject } from 'ramda';

export interface IPayloadItem<T> {
  payload: T;
}

export interface IUidsPayload {
  uid: string;
}

export const updateSimpleValue = <T>(_state: T, { payload }: IPayloadItem<T>) =>
  payload;

export const addUids = <T>(
  state: T[],
  { payload = [] }: { payload: IUidsPayload[] }
) => [...state, ...payload.map(({ uid }) => uid)];

export const addByUid = <T, T2>(
  state: T,
  { payload = [] }: { payload: Array<T2 & IUidsPayload> }
) =>
  payload.reduce(
    (result, item) => ({
      ...result,
      [item.uid]: item,
    }),
    state
  );

export const deleteUid = (state: string[], { payload }: { payload: string }) =>
  reject(equals(payload), state);

export const deleteByUid = <T>(state: T, { payload }: { payload: string }) =>
  omit([payload], state);

export const updateUids = <T>(
  state: T[],
  { payload }: { payload: IUidsPayload }
) => [...state, payload.uid];

export const updateByUid = <T>(
  state: T,
  { payload }: { payload: IUidsPayload }
) => ({
  ...state,
  [payload.uid]: payload,
});
