import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { IB2BActivation } from './types';
import resetReducer from 'utils/resetReducer';
import {
  SET_PASSWORD,
  SET_PASSWORD_ERROR,
  SET_FIRST_NAME,
  SET_FIRST_NAME_ERROR,
  SET_LOADING,
  RESET_STATE,
} from './actionTypes';

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const passwordErrorReducer = handleAction<string, string>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const firstNameReducer = handleAction<string, string>(
  SET_FIRST_NAME,
  updateSimpleValue,
  ''
);

const firstNameErrorReducer = handleAction<string, string>(
  SET_FIRST_NAME_ERROR,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const passwordResetReducers = combineReducers<IB2BActivation>({
  password: passwordReducer,
  firstName: firstNameReducer,
  firstNameError: firstNameErrorReducer,
  isLoading: isLoadingReducer,
  passwordError: passwordErrorReducer,
});

export default resetReducer(RESET_STATE, passwordResetReducers);
