import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import useOnClickOutside from 'utils/useOnClickOutside';
import hideable from 'utils/hideable';
import { ROUTES } from 'navigation/constants';
import { getCurrentLocation } from 'navigation/selectors';
import { logout } from 'features/Auth/actions';
import { IReduxState } from 'state/types';
import { ACCOUNT_PAGES } from 'features/AccountPage/constants';

interface IOwnProps {
  closeHandler: () => void;
}

interface IMapStateToProps {
  shouldShowAccountLink: boolean;
  shouldShowHomeLink: boolean;
}

interface IMapDispatchToProps {
  onLogout: () => void;
}

type TMenu = IMapDispatchToProps & IOwnProps & IMapStateToProps;

const Container = styled.div({
  position: 'absolute',
  top: 50,
  right: -26,
});

const ArrowBox = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  borderRadius: 2,
  padding: '8px 16px',
  position: 'relative',
  minWidth: 150,
  cursor: 'default',
  '&:after': {
    content: '" "',
    position: 'absolute',
    right: '15px',
    top: '-15px',
    borderTop: 'none',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    borderBottom: `15px solid ${props.theme.colors.white}`,
  },
}));

const MenuText = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  margin: '4px 0',
  cursor: 'pointer',
});

const InternalLink = styled(Link)({
  color: 'unset',
  textDecoration: 'none',
});

const ActionButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: 0,
});

const Menu: React.FC<TMenu> = ({
  closeHandler,
  onLogout,
  shouldShowAccountLink,
  shouldShowHomeLink,
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, closeHandler);

  return (
    <Container ref={ref}>
      <ArrowBox>
        {shouldShowAccountLink && (
          <InternalLink to={`${ROUTES.ACCOUNT}${ACCOUNT_PAGES.DETAILS}`}>
            <MenuText>Account</MenuText>
          </InternalLink>
        )}
        {shouldShowHomeLink && (
          <InternalLink to={ROUTES.MAIN}>
            <MenuText>Home</MenuText>
          </InternalLink>
        )}
        <ActionButton onClick={onLogout}>
          <MenuText>Logout</MenuText>
        </ActionButton>
      </ArrowBox>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => {
  const currentPath = getCurrentLocation(state);

  return {
    shouldShowAccountLink: currentPath === ROUTES.MAIN,
    shouldShowHomeLink: currentPath !== ROUTES.MAIN,
  };
};

const mapDispatchToProps = {
  onLogout: logout,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable<TMenu>(Menu));
