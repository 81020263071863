import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getPassword = (state: IReduxState) => state?.[NAME]?.password;

export const getToken = (state: IReduxState) => state?.[NAME]?.token;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const isSuccess = (state: IReduxState) => state?.[NAME]?.isSuccess;

export const getPasswordError = (state: IReduxState) =>
  state?.[NAME]?.passwordError;

export const isFormVisible = (state: IReduxState) =>
  state?.[NAME]?.isFormVisible;
