import styled from '@emotion/styled';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import Header from 'features/Header/components';
import Text from 'components/Text';
import Button from 'components/Button';
import FullScreenWrapper from 'components/FullScreenWrapper';
import { ReactComponent as Icon } from 'assets/success_icon.svg';
import { ROUTES } from 'navigation/constants';
import { TDispatch } from 'types/common';
import { IReduxState } from 'state/types';

interface IMapDispatchToProps {
  onClick: () => void;
}

type TSuccessPage = IMapDispatchToProps;

const Container = styled.div({
  width: '100%',
  maxWidth: 364,
  textAlign: 'center',
  marginLeft: 20,
  marginRight: 20,
});

const StyledText = styled(Text)({
  marginTop: 24,
  marginBottom: 32,
});

const SuccessPage: React.FC<TSuccessPage> = ({ onClick }) => (
  <FullScreenWrapper>
    <Header />
    <Container>
      <Icon />
      <StyledText align="center" type="h1">
        Your purchase was successful
      </StyledText>
      <Button onClick={onClick} text="Go to homepage" />
    </Container>
  </FullScreenWrapper>
);

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(push(ROUTES.MAIN)),
});

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(SuccessPage);
