import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ACCOUNT_PAGES, NAVIGATION_BUTTONS } from '../constants';

import { IReduxState } from 'state/types';
import { BREAKPOINTS } from 'theme/constants';
import { getCurrentLocation } from 'navigation/selectors';
import { ROUTES } from 'navigation/constants';

interface IStylingProps {
  isActive: boolean;
}

interface IOwnProps {
  route: ACCOUNT_PAGES;
  text: NAVIGATION_BUTTONS;
}

type TNavigationButton = IStylingProps & IOwnProps;

const Button = styled(Link)<IStylingProps>((props) => ({
  opacity: props.isActive ? 1 : 0.5,
  color: props.theme.colors.white,
  fontWeight: 'bold',
  background: 'none',
  border: 'none',
  outline: 'none',
  textAlign: 'left',
  marginTop: 16,
  marginBottom: 16,
  cursor: 'pointer',
  fontSize: 15,
  textDecoration: 'none',
  [BREAKPOINTS.XXL_MOBILE]: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const NavigationButton: React.FC<TNavigationButton> = ({
  isActive,
  text,
  route,
}) => {
  return (
    <Button isActive={isActive} to={`${ROUTES.ACCOUNT}${route}`}>
      {text}
    </Button>
  );
};

const mapStateToProps = (state: IReduxState, { route }: IOwnProps) => ({
  isActive: getCurrentLocation(state) === `${ROUTES.ACCOUNT}${route}`,
});

export default connect<IStylingProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(NavigationButton);
