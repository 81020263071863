import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/logo.svg';
import FullScreenWrapper from 'components/FullScreenWrapper';
import SendPasswordResetEmailForm from './SendPasswordResetEmailForm';
import SendEmailSuccess from './SendEmailSuccess';
import { ROUTES } from 'navigation/constants';

const Container = styled.form({
  maxWidth: '364px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
});

const SendPasswordResetEmail = () => (
  <FullScreenWrapper>
    <Container>
      <Link to={ROUTES.LOGIN}>
        <Logo />
      </Link>
      <SendPasswordResetEmailForm />
      <SendEmailSuccess />
    </Container>
  </FullScreenWrapper>
);

export default SendPasswordResetEmail;
