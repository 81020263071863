import { NAME } from './constants';

import { IReduxState } from 'state/types';

export const getNewPassword = (state: IReduxState) =>
  state?.[NAME]?.newPassword;

export const getOldPassword = (state: IReduxState) =>
  state?.[NAME]?.oldPassword;

export const getNewPasswordError = (state: IReduxState) =>
  state?.[NAME]?.newPasswordError;

export const getOldPasswordError = (state: IReduxState) =>
  state?.[NAME]?.oldPasswordError;

export const isPasswordChangeLoading = (state: IReduxState) =>
  state?.[NAME]?.isLoading;
