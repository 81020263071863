import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import EmailResend from './EmailResend';
import VerificationSuccess from './VerificationSuccess';
import { handleAccountVerification, resetState } from '../actions';
import { IReduxState } from 'state/types';

interface IMapDispatchToProps {
  onMount: () => void;
  onUnmount: () => void;
}

type TAccountVerification = IMapDispatchToProps;

const AccountVerification: React.FC<TAccountVerification> = ({
  onMount,
  onUnmount,
}) => {
  useEffect(() => {
    onMount();

    return onUnmount;
  }, [onMount, onUnmount]);

  return (
    <div>
      <h2>Account verification</h2>
      <EmailResend />
      <VerificationSuccess />
    </div>
  );
};

const mapDispatchToProps = {
  onMount: handleAccountVerification,
  onUnmount: resetState,
};

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(AccountVerification);
