import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

interface IButton {
  onClick: () => void;
  text: string;
  isDisabled?: boolean;
}

interface IDisabledProps {
  disabled?: boolean;
}

interface IButtonTypeProps {
  buttonType?: TButtonType;
}

type TButtonType = 'primary' | 'secondary';

type TButton = IButton & IDisabledProps & IButtonTypeProps;

interface IGetStyleByType {
  type?: TButtonType;
  theme: Theme;
}

export const getStyleByType = ({ type = 'primary', theme }: IGetStyleByType) =>
  ({
    primary: {
      border: 'none',
      backgroundColor: theme.colors.lightBlue,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.darkBlue,
      },
    },
    secondary: {
      border: '1px solid rgba(255, 255, 255, 0.3)',
      background: 'transparent',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
      },
    },
  }[type]);

const StyledButton = styled.button<IDisabledProps & IButtonTypeProps>(
  (props) => ({
    color: props.theme.colors.white,
    height: '46px',
    borderRadius: '100px',
    outline: 'none',
    width: '100%',
    fontSize: '18px',
    fontFamily: 'Poppins',
    opacity: props.disabled ? '60%' : '',
    ...getStyleByType({ theme: props.theme, type: props.buttonType }),
  })
);

const Button: React.FC<TButton> = ({
  onClick,
  text,
  isDisabled,
  buttonType = 'primary',
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    !!e && e.preventDefault();

    onClick();
  };

  return (
    <StyledButton
      role="button"
      buttonType={buttonType}
      onClick={handleClick}
      disabled={isDisabled}
      {...rest}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
