import styled from '@emotion/styled';

interface ISocialAuthButton {
  href: string;
  isDisabled?: boolean;
  icon?: JSX.Element;
  text: string;
}

const TextContainer = styled.span({
  marginLeft: '8px',
});

const StyledButton = styled.a((props) => ({
  color: props.theme.colors.white,
  height: '46px',
  borderRadius: '100px',
  border: `1px solid rgba(255, 255, 255, 0.3)`,
  outline: 'none',
  width: '100%',
  fontSize: '18px',
  fontFamily: 'Poppins',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  ':hover': {
    backgroundColor: props.theme.colors.white,
    color: props.theme.colors.black,
  },
}));

const SocialAuthButton: React.FC<ISocialAuthButton> = ({
  href,
  icon,
  text,
  ...rest
}) => (
  <StyledButton href={href} {...rest}>
    {icon}
    <TextContainer>{text}</TextContainer>
  </StyledButton>
);

export default SocialAuthButton;
