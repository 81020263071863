import styled from '@emotion/styled';
import { connect } from 'react-redux';

import SocialSignIn from 'features/SocialSignIn/components';
import LegalText from 'components/LegalText';
import Input from 'components/SignInInput';
import FullScreenWrapper from 'components/FullScreenWrapper';
import Button from 'components/Button';
import Link from 'components/Link';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as Envelope } from 'assets/envelope.svg';
import { ReactComponent as Key } from 'assets/key.svg';
import { ROUTES } from 'navigation/constants';
import { IReduxState } from 'state/types';
import { setEmail, setPassword, handleLogin } from '../actions';
import {
  getEmail,
  getPassword,
  isLoading,
  isEmailError,
  isPasswordError,
  getErrorText,
} from '../selectors';

interface IMapStateToProps {
  email: string;
  password: string;
  isButtonDisabled: boolean;
  shouldShowEmailError: boolean;
  shouldShowPasswordError: boolean;
  errorText: string;
}

interface IMapDispatchToProps {
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: () => void;
}

type TLogin = IMapStateToProps & IMapDispatchToProps;

const Container = styled.form({
  maxWidth: '364px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
});

const LoginNote = styled(Text)({
  marginTop: '40px',
  marginBottom: '32px',
});

const HelperNote = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
  marginRight: '5px',
}));

const HelperContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '16px',
  marginBottom: '16px',
});

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
}));

const Login: React.FC<TLogin> = ({
  email,
  password,
  isButtonDisabled,
  errorText,
  shouldShowEmailError,
  shouldShowPasswordError,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => (
  <FullScreenWrapper>
    <Container>
      <Logo />
      <LoginNote role="title" type="h2">
        Log into yours account
      </LoginNote>
      <Error role="error">{errorText}</Error>
      <Input
        isError={shouldShowEmailError}
        icon={<Envelope />}
        placeholder="Email"
        value={email}
        onChange={onEmailChange}
        type="email"
      />
      <Input
        isError={shouldShowPasswordError}
        icon={<Key />}
        placeholder="Password"
        value={password}
        onChange={onPasswordChange}
        type="password"
      />
      <HelperContainer>
        <HelperNote>Forgot password?</HelperNote>
        <Link to={ROUTES.SEND_PASSWORD_RESET_EMAIL}>Click here</Link>
      </HelperContainer>
      <Button text="Sign in" onClick={onSubmit} isDisabled={isButtonDisabled} />
      <Separator text="OR" />
      <SocialSignIn />
      <HelperContainer>
        <HelperNote>Don`t have an account yet?</HelperNote>
        <Link to={ROUTES.REGISTRATION}>Sign up</Link>
      </HelperContainer>
      <LegalText />
    </Container>
  </FullScreenWrapper>
);

const mapStateToProps = (state: IReduxState) => ({
  email: getEmail(state),
  password: getPassword(state),
  isButtonDisabled: isLoading(state),
  shouldShowEmailError: isEmailError(state),
  shouldShowPasswordError: isPasswordError(state),
  errorText: getErrorText(state),
});

const mapDispatchToProps = {
  onEmailChange: setEmail,
  onPasswordChange: setPassword,
  onSubmit: handleLogin,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(Login);
