import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { ISendPasswordResetEmail } from './types';
import {
  SET_EMAIL,
  SET_LOADING,
  SET_SUCCESS,
  SET_EMAIL_ERROR,
  SET_RESEND_FLOW,
} from './actionTypes';

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const isSuccessReducer = handleAction<boolean, boolean>(
  SET_SUCCESS,
  updateSimpleValue,
  false
);

const resendFlowReducer = handleAction<boolean, boolean>(
  SET_RESEND_FLOW,
  updateSimpleValue,
  false
);

const emailErrorReducer = handleAction<string, string>(
  SET_EMAIL_ERROR,
  updateSimpleValue,
  ''
);

export default combineReducers<ISendPasswordResetEmail>({
  email: emailReducer,
  isLoading: isLoadingReducer,
  isSuccess: isSuccessReducer,
  emailError: emailErrorReducer,
  isResendFlow: resendFlowReducer,
});
