import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Global, css, useTheme } from '@emotion/react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/fonts/index.css';

import Navigation from 'navigation';
import LoadingScreen from 'components/LoadingScreen';
import { handleAppMount } from 'features/App/actions';
import { isAppLoading } from 'features/App/selectors';
import { IReduxState } from 'state/types';
import { getAccessToken } from 'features/Auth/selectors';
import { handleAutoPurchase } from 'features/Purchase/actions';
import { userHasSubscription } from 'features/Subscription/selectors';

interface IMapStateToProps {
  shouldShowLoadingScreen: boolean;
  hasUserSubscription: boolean | null;
  accessToken: string;
}

interface IMapDispatchToProps {
  onMount: () => void;
  onAccessTokenChange: () => void;
}

type TApp = IMapStateToProps & IMapDispatchToProps;

const App: React.FC<TApp> = ({
  onMount,
  onAccessTokenChange,
  shouldShowLoadingScreen,
  accessToken,
  hasUserSubscription,
}) => {
  const theme = useTheme();

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!!accessToken && hasUserSubscription !== null) {
      onAccessTokenChange();
    }
  }, [accessToken, hasUserSubscription, onAccessTokenChange]);

  return (
    <>
      <Global
        styles={css`
          #root,
          html,
          body {
            margin: 0px;
            padding: 0px;
            font-family: Poppins;
            min-height: 100vh;
            background-color: ${theme.colors.backgroundDark};
            .Toastify__toast {
              font-family: Poppins;
              text-align: center;
            }

            .Toastify__toast--success {
              background-color: ${theme.colors.green};
            }

            .Toastify__toast--error {
              background-color: ${theme.colors.error};
            }
          }
        `}
      />
      <Navigation isVisible={!shouldShowLoadingScreen} />
      <LoadingScreen isVisible={shouldShowLoadingScreen} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        transition={Slide}
      />
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  shouldShowLoadingScreen: isAppLoading(state),
  accessToken: getAccessToken(state),
  hasUserSubscription: userHasSubscription(state),
});

const mapDispatchToProps = {
  onMount: handleAppMount,
  onAccessTokenChange: handleAutoPurchase,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(App);
