import styled from '@emotion/styled';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from 'components/Button';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { handleAutoRenewalClick, handleUpdatePaymentDetails } from '../actions';
import {
  isAutoRenewLoading,
  getSubscriptionManagementText,
  shouldShowRenewalManagementButton,
  shouldShowUpdatePaymentDetailsButton,
} from '../selectors';
import {
  getSubscriptionValidFromDate,
  getSubscriptionValidToDate,
  getSubscriptionName,
  userHasSubscription,
  isAutoRenewEnabled,
  isUpdatePaymentDetailsLoading,
} from 'features/Subscription/selectors';

interface IMapStateToProps {
  validFromText: string;
  validToText: string;
  subscriptionName: string;
  renewalButtonText: string;
  subManagementText: string;
  isRenewalButtonDisabled: boolean;
  isUpdatePaymentDetailsButtonDisabled: boolean;
  isRenewalButtonVisible: boolean;
  isUpdatePaymentDetailsButtonVisible: boolean;
}

interface IMapDispatchToProps {
  onRenewalButtonClick: () => void;
  onUpdatePaymentDetailsButtonClick: () => void;
}

type TActiveSubscription = IMapStateToProps & IMapDispatchToProps;

const Title = styled(Text)({
  fontSize: 40,
});

const LightText = styled.span({
  fontWeight: 100,
});

const List = styled.ul({
  margin: '35px 0',
  paddingLeft: 20,
});

const ListItem = styled.li((props) => ({
  opacity: 0.8,
  margin: '5px 0',
  '::marker': {
    color: props.theme.colors.electric,
  },
}));

const StyledButton = styled(Button)({
  maxWidth: 280,
  margin: 6,
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const ActiveSubscription: React.FC<TActiveSubscription> = ({
  validFromText,
  validToText,
  renewalButtonText,
  subscriptionName,
  isRenewalButtonDisabled,
  isUpdatePaymentDetailsButtonDisabled,
  subManagementText,
  isRenewalButtonVisible,
  isUpdatePaymentDetailsButtonVisible,
  onRenewalButtonClick,
  onUpdatePaymentDetailsButtonClick,
}) => (
  <>
    <Title>
      Your {subscriptionName}
      <LightText> subscription</LightText>
    </Title>
    <List>
      <ListItem>
        <Text>{validFromText}</Text>
      </ListItem>
      <ListItem>
        <Text>{validToText}</Text>
      </ListItem>
      {!!subManagementText && (
        <ListItem>
          <Text>{subManagementText}</Text>
        </ListItem>
      )}
    </List>
    <ButtonsContainer>
      {isRenewalButtonVisible && (
        <StyledButton
          onClick={onRenewalButtonClick}
          text={renewalButtonText}
          buttonType="secondary"
          isDisabled={isRenewalButtonDisabled}
        />
      )}
      {isUpdatePaymentDetailsButtonVisible && (
        <StyledButton
          onClick={onUpdatePaymentDetailsButtonClick}
          text="Update payment details"
          isDisabled={isUpdatePaymentDetailsButtonDisabled}
        />
      )}
    </ButtonsContainer>
  </>
);

const mapStateToProps = (state: IReduxState) => {
  const autoRenewEnabled = isAutoRenewEnabled(state);

  return {
    isVisible: !!userHasSubscription(state),
    validTo: moment(getSubscriptionValidToDate(state)).format('ll'),
    subscriptionName: getSubscriptionName(state),
    isRenewalButtonDisabled: isAutoRenewLoading(state),
    isUpdatePaymentDetailsButtonDisabled: isUpdatePaymentDetailsLoading(state),
    renewalButtonText: autoRenewEnabled ? 'Cancel' : 'Renew subscription',
    subManagementText: getSubscriptionManagementText(state),
    isRenewalButtonVisible: shouldShowRenewalManagementButton(state),
    isUpdatePaymentDetailsButtonVisible:
      shouldShowUpdatePaymentDetailsButton(state),
    validToText: `${
      autoRenewEnabled
        ? 'Yours subscription next billing date is'
        : 'Yours subscription expires at'
    } ${moment(getSubscriptionValidToDate(state)).format('ll')}`,
    validFromText: `Yours subscription has started at ${moment(
      getSubscriptionValidFromDate(state)
    ).format('ll')}`,
  };
};

const mapDispatchToProps = {
  onRenewalButtonClick: handleAutoRenewalClick,
  onUpdatePaymentDetailsButtonClick: handleUpdatePaymentDetails,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(ActiveSubscription));
