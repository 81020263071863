import { createAction } from 'redux-actions';
import { AxiosResponse } from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

import { TThunk } from 'types/common';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { ERROR_TEXTS, STORAGE_KEYS } from 'utils/constants';
import { storageRemoveItem } from 'utils/helpers';
import { userHasSubscription } from 'features/Subscription/selectors';
import { PAYMENT_PROVIDER } from './constants';
import { getPlansUids, isLoading, getSelectedPlan } from './selectors';
import { COOKIE_KEY, getCookie, removeCookie } from 'utils/cookies';
import {
  SET_PLANS,
  SET_SELECTED_PLAN,
  SET_LOADING,
  SET_AUTO_PURCHASE_LOADING,
} from './actionTypes';
import {
  TFormattedPlan,
  IPlan,
  IStripeSessionBody,
  IStripeSessionResponse,
} from './types';

export const setPlans = createAction<TFormattedPlan[]>(SET_PLANS);
export const setSelectedPlan = createAction<string>(SET_SELECTED_PLAN);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setAutoPurchaseLoading = createAction<boolean>(
  SET_AUTO_PURCHASE_LOADING
);

export const fetchPlans = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const uids = getPlansUids(state);

  if (!!uids.length) {
    return;
  }

  callCoreApi({
    method: METHODS.GET,
    url: API_URLS.PLANS,
    queryParams: `?provider=${PAYMENT_PROVIDER}`,
  })
    .then((response: AxiosResponse<IPlan[]>) => {
      const data = response?.data?.map((plan: IPlan) => ({
        uid: plan.id,
        ...plan,
      }));

      if (!data.length) {
        return;
      }

      dispatch(setPlans(data));
      dispatch(setSelectedPlan(data?.[0]?.id));
    })
    .catch(() => {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
    });
};

export const handlePlanPurchase = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const selectedPlan = getSelectedPlan(state);
  const isPurchaseLoading = isLoading(state);

  if (isPurchaseLoading && !selectedPlan) {
    return;
  }

  dispatch(setLoading(true));
  dispatch(purchaseRequest(selectedPlan));
};

export const handleAutoPurchase = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const planId = getCookie(COOKIE_KEY.WEB_PLAN_ID);
  const shouldNotLetRedirectToStripe = userHasSubscription(state);

  if (shouldNotLetRedirectToStripe || !planId) {
    removeCookie(COOKIE_KEY.WEB_PLAN_ID);
    dispatch(setAutoPurchaseLoading(false));

    return;
  }

  removeCookie(COOKIE_KEY.WEB_PLAN_ID);

  dispatch(setAutoPurchaseLoading(true));
  dispatch(purchaseRequest(planId));
};

export const purchaseRequest =
  (planId: string): TThunk =>
  (dispatch) => {
    const coupon = getCookie(COOKIE_KEY.COUPON);

    if (!!coupon) {
      storageRemoveItem(STORAGE_KEYS.COUPON);
    }

    callCoreApi<IStripeSessionBody>({
      method: METHODS.POST,
      url: API_URLS.STRIPE_SESSION,
      data: {
        provider_product_id: planId,
        coupon: coupon,
      },
      authorized: true,
    })
      .then(async (response: AxiosResponse<IStripeSessionResponse>) => {
        const key = process.env.REACT_APP_STRIPE_PUBLIC;

        if (!key) {
          return;
        }

        const stripe = await loadStripe(key);

        await stripe?.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      })
      .catch(() => {
        toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setAutoPurchaseLoading(false));
      });
  };
