import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import SettingsButton from './SettingsButton';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ROUTES } from 'navigation/constants';
import { BREAKPOINTS } from 'theme/constants';

interface IContainer {
  isScrollMenu: boolean;
}

const Container = styled.div<IContainer>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px 140px',
  backgroundColor: `rgba(57, 108, 216, 0)`,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 100,
  backdropFilter: 'blur(0)',
  transition: 'all 0.3s ease-out',
  [BREAKPOINTS.TABLET]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  ...(props.isScrollMenu && {
    backgroundColor: `rgba(57, 108, 216, 0.84)`,
    backdropFilter: 'blur(20px)',
    paddingTop: 4,
    paddingBottom: 4,
    transition: 'all 0.5s ease-out',
  }),
}));

const StyledLogo = styled(Logo)({
  width: 96,
});

const Header: React.FC = () => {
  const [isScrollMenu, setScrollMenu] = useState<boolean>(false);

  const scrollListener = () => {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 21) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <Container isScrollMenu={isScrollMenu}>
      <Link to={ROUTES.MAIN}>
        <StyledLogo />
      </Link>
      <SettingsButton />
    </Container>
  );
};

export default Header;
