import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { loadStripe } from '@stripe/stripe-js';

import {
  ISubscriptionData,
  IAutoRenewBody,
  PAYMENT_PROVIDERS,
  TSubscriptionStatus,
} from './types';
import {
  getPaymentProvider,
  isAutoRenewEnabled,
  isAutoRenewLoading,
  getSubscriptionId,
  isUpdatePaymentDetailsLoading,
} from './selectors';
import {
  SET_SUBSCRIPTION_NAME,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  SET_SUBSCRIPTION_VALID_TO_DATE,
  SET_PAYMENT_PROVIDER,
  SET_AUTO_RENEW_ENABLED,
  SET_SUBSCRIPTION_ID,
  SET_USER_HAS_SUBSCRIPTION,
  SET_PROVIDER_PRODUCT_ID,
  SET_AUTO_RENEW_LOADING,
  SET_UPDATE_PAYMENT_DETAILS_LOADING,
} from './actionTypes';

import { TThunk } from 'types/common';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { ERROR_TEXTS } from 'utils/constants';
import { IStripeSessionResponse } from 'features/Purchase/types';

export const setSubscriptionName = createAction<string>(SET_SUBSCRIPTION_NAME);
export const setSubscriptionId = createAction<string>(SET_SUBSCRIPTION_ID);
export const setUpdatePaymentLoading = createAction<boolean>(
  SET_UPDATE_PAYMENT_DETAILS_LOADING
);
export const setSubscriptionStatus = createAction<TSubscriptionStatus>(
  SET_SUBSCRIPTION_STATUS
);
export const setAutoRenewEnabled = createAction<boolean>(
  SET_AUTO_RENEW_ENABLED
);
export const setProviderProductId = createAction<string>(
  SET_PROVIDER_PRODUCT_ID
);
export const setUserHasSubscription = createAction<boolean>(
  SET_USER_HAS_SUBSCRIPTION
);
export const setPaymentProvider =
  createAction<PAYMENT_PROVIDERS>(SET_PAYMENT_PROVIDER);
export const setSubscriptionValidFromDate = createAction<string>(
  SET_SUBSCRIPTION_VALID_FROM_DATE
);
export const setSubscriptionValidToDate = createAction<string>(
  SET_SUBSCRIPTION_VALID_TO_DATE
);
export const setAutoRenewLoading = createAction<boolean>(
  SET_AUTO_RENEW_LOADING
);

export const setSubscriptionData =
  (subscription: ISubscriptionData | null): TThunk =>
  (dispatch) => {
    if (!subscription) {
      dispatch(setUserHasSubscription(false));

      return;
    }

    dispatch(setUserHasSubscription(true));
    dispatch(setSubscriptionId(subscription.id));
    dispatch(setAutoRenewEnabled(subscription.auto_renew));
    dispatch(setPaymentProvider(subscription.provider_product.provider.name));
    dispatch(setSubscriptionName(subscription.provider_product.product.name));
    dispatch(setProviderProductId(subscription.provider_product.id));
    dispatch(setSubscriptionValidToDate(subscription.period_end));
    dispatch(setSubscriptionValidFromDate(subscription.period_start));
    dispatch(setSubscriptionStatus(subscription.status));
  };

export const handleAutoRenewalClick = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const isLoading = isAutoRenewLoading(state);
  const paymentProvider = getPaymentProvider(state);

  if (isLoading) {
    return;
  }

  dispatch(setAutoRenewLoading(true));

  if (paymentProvider === PAYMENT_PROVIDERS.STRIPE) {
    dispatch(handleAutoRenewalChange());
  }

  if (paymentProvider === PAYMENT_PROVIDERS.PAYPAL) {
    dispatch(handlePaypalCancellation());
  }
};

export const handlePaypalCancellation = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const shouldCancel = isAutoRenewEnabled(state);
  const subscriptionId = getSubscriptionId(state);

  if (!shouldCancel) {
    dispatch(setAutoRenewLoading(false));

    return;
  }

  callCoreApi({
    method: METHODS.POST,
    url: API_URLS.CANCEL_PAYPAL_SUBSCRIPTION,
    queryParams: `${subscriptionId}/cancel/`,
    authorized: true,
  })
    .then(() => {
      toast.success('Subscription was cancelled successfully.');
      dispatch(setAutoRenewEnabled(false));
    })
    .catch(() => {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
    })
    .finally(() => {
      dispatch(setAutoRenewLoading(false));
    });
};

export const handleAutoRenewalChange = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const subscriptionId = getSubscriptionId(state);
  const shouldCancel = isAutoRenewEnabled(state);

  callCoreApi<IAutoRenewBody>({
    method: METHODS.PATCH,
    url: API_URLS.MANAGE_SUB,
    queryParams: `${subscriptionId}/`,
    authorized: true,
    data: {
      cancel_at_period_end: shouldCancel,
    },
  })
    .then(() => {
      dispatch(setAutoRenewEnabled(!shouldCancel));

      const successText = shouldCancel
        ? 'Subscription was cancelled successfully.'
        : 'Subscription was renewed successfully.';

      toast.success(successText);
    })
    .catch(() => {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
    })
    .finally(() => {
      dispatch(setAutoRenewLoading(false));
    });
};

export const handleUpdatePaymentDetails =
  (): TThunk => (dispatch, getState) => {
    const state = getState();
    const subscriptionId = getSubscriptionId(state);
    const isLoading = isUpdatePaymentDetailsLoading(state);

    if (isLoading) {
      return;
    }

    dispatch(setUpdatePaymentLoading(true));

    callCoreApi({
      method: METHODS.POST,
      url: API_URLS.MANAGE_SUB,
      queryParams: `${subscriptionId}/update-payment-method/`,
      authorized: true,
    })
      .then(async (response: AxiosResponse<IStripeSessionResponse>) => {
        const key = process.env.REACT_APP_STRIPE_PUBLIC;

        if (!key) {
          return;
        }

        const stripe = await loadStripe(key);

        await stripe?.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      })
      .catch(() => {
        toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      })
      .finally(() => {
        dispatch(setUpdatePaymentLoading(false));
      });
  };
