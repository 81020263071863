import styled from '@emotion/styled';

import Plans from './Plans';
import AppLinks from './AppLinks';
import { BREAKPOINTS } from 'theme/constants';

const Container = styled.div((props) => ({
  background: props.theme.colors.darkBlueBackground,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  alignSelf: 'stretch',
  [BREAKPOINTS.XL_MOBILE]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const Purchase: React.FC = () => (
  <Container>
    <Plans />
    <AppLinks />
  </Container>
);

export default Purchase;
