import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Menu from './Menu';
import { ReactComponent as Arrow } from 'assets/menu_arrow.svg';
import { IReduxState } from 'state/types';
import { getName } from 'features/Auth/selectors';

interface IMapStateToProps {
  name: string;
  nameLetters: string;
}

type TSettingsButton = IMapStateToProps;

const Container = styled.div({
  position: 'relative',
  cursor: 'pointer',
});

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const NameLetter = styled.div((props) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  padding: '9px 10px',
  borderRadius: 30,
  color: props.theme.colors.white,
  fontSize: 16,
}));

const NameContainer = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 16,
  margin: '0 12px',
}));

const SettingsButton: React.FC<TSettingsButton> = ({ name }) => {
  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);

  return (
    <Container>
      <ButtonContainer onClick={() => setMenuVisibility(!isMenuVisible)}>
        <NameLetter>
          {`${name.charAt(0)}${name.charAt(2).toLocaleUpperCase()}`}
        </NameLetter>
        <NameContainer role="navigation">{name}</NameContainer>
        <Arrow />
      </ButtonContainer>
      <Menu
        isVisible={isMenuVisible}
        closeHandler={() => setMenuVisibility(false)}
      />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => {
  const firstName = getName(state);

  return {
    name: firstName.toLocaleUpperCase(),
    nameLetters: `
      ${firstName.charAt(0)}${firstName.charAt(2).toLocaleUpperCase()}
    `,
  };
};

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(SettingsButton);
