import { combineReducers, Reducer } from 'redux';
import { RouterState } from 'redux-first-history/src/reducer';
import resetReducer from 'utils/resetReducer';
import { LOGOUT } from 'features/Auth/actionTypes';

import { IReduxState } from './types';
import accountVerification from 'features/AccountVerification';
import login from 'features/Login';
import registration from 'features/Registration';
import auth from 'features/Auth';
import sendPasswordResetEmail from 'features/SendPasswordResetEmail';
import passwordReset from 'features/PasswordReset';
import purchase from 'features/Purchase';
import subscription from 'features/Subscription';
import accountDetails from 'features/AccountDetails';
import b2bActivation from 'features/B2BActivation';

export default (routerReducer: Reducer<RouterState>) =>
  combineReducers<IReduxState>({
    router: routerReducer,
    [login.constants.NAME]: resetReducer(LOGOUT, login.reducer),
    [registration.constants.NAME]: resetReducer(LOGOUT, registration.reducer),
    [b2bActivation.constants.NAME]: resetReducer(LOGOUT, b2bActivation.reducer),
    [auth.constants.NAME]: resetReducer(LOGOUT, auth.reducer),
    [passwordReset.constants.NAME]: resetReducer(LOGOUT, passwordReset.reducer),
    [purchase.constants.NAME]: resetReducer(LOGOUT, purchase.reducer),
    [subscription.constants.NAME]: resetReducer(LOGOUT, subscription.reducer),
    [accountDetails.constants.NAME]: resetReducer(
      LOGOUT,
      accountDetails.reducer
    ),
    [accountVerification.constants.NAME]: resetReducer(
      LOGOUT,
      accountVerification.reducer
    ),
    [sendPasswordResetEmail.constants.NAME]: resetReducer(
      LOGOUT,
      sendPasswordResetEmail.reducer
    ),
  });
