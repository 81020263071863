import { connect } from 'react-redux';

import hideable, { TWithHideableProp } from 'utils/hideable';
import { IReduxState } from 'state/types';
import { isVerificationSuccessful } from '../selectors';

const VerificationSuccess: React.FC = () => (
  <div>
    <h2>Your account verification is successful</h2>
  </div>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: isVerificationSuccessful(state),
});

export default connect<
  TWithHideableProp<unknown>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(VerificationSuccess));
