import Cookies from 'js-cookie';

import { YOURS_APP_DOMAIN } from './constants';

export enum COOKIE_KEY {
  COUPON = 'coupon',
  YOURS_CID = 'yours_cid',
  FBCLID = 'fbclid',
  GCLID = 'gclid',
  _FBP = '_fbp',
  _FBC = '_fbc',
  AF_USER_ID = 'afUserId',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  WEB_PLAN_ID = 'web_plan_id',
  IMPACT_CLICK_ID = 'impact_click_id',
}

export const setCookie = (key: COOKIE_KEY, value: string) => {
  Cookies.set(key, value, { path: '/', domain: YOURS_APP_DOMAIN });
};

export const removeCookie = (key: COOKIE_KEY) => {
  Cookies.remove(key, { path: '/', domain: YOURS_APP_DOMAIN });
};

export const getCookie = (key: COOKIE_KEY) => {
  return Cookies.get(key) || '';
};
