import styled from '@emotion/styled';
import { Routes, Route } from 'react-router-dom';

import Navigation from './Navigation';
import AccountDetails from 'features/AccountDetails/components';
import Subscription from 'features/Subscription/components';
import { ACCOUNT_PAGES } from '../constants';

import FullScreenWrapper from 'components/FullScreenWrapper';
import Header from 'features/Header/components';
import { BREAKPOINTS } from 'theme/constants';

const Container = styled.div({
  width: '100%',
  maxWidth: 1160,
  display: 'flex',
  height: 764,
  padding: '160px 140px',
  [BREAKPOINTS.TABLET]: {
    maxWidth: 'unset',
    paddingLeft: 20,
    paddingRight: 20,
  },
  [BREAKPOINTS.XXL_MOBILE]: {
    flexDirection: 'column',
  },
});

const Content = styled.div((props) => ({
  flexGrow: 1,
  padding: '60px 100px',
  backgroundColor: props.theme.colors.accountPageGray,
  [BREAKPOINTS.TABLET]: {
    padding: '30px 40px',
  },
}));

const AccountPage: React.FC = () => {
  return (
    <FullScreenWrapper>
      <Header />
      <Container>
        <Navigation />
        <Content>
          <Routes>
            <Route
              path={`${ACCOUNT_PAGES.DETAILS}`}
              element={<AccountDetails />}
            />
            <Route
              path={`${ACCOUNT_PAGES.SUBSCRIPTION}`}
              element={<Subscription />}
            />
          </Routes>
        </Content>
      </Container>
    </FullScreenWrapper>
  );
};

export default AccountPage;
