import { Routes, Route } from 'react-router-dom';

import Login from 'features/Login/components';
import FacebookSignInContainer from 'features/Login/components/FacebookSignInContainer';
import GoogleSignInContainer from 'features/Login/components/GoogleSignInContainer';
import AccountVerification from 'features/AccountVerification/components';
import Registration from 'features/Registration/components';
import SendPasswordResetEmail from 'features/SendPasswordResetEmail/components';
import PasswordReset from 'features/PasswordReset/components';
import Home from 'features/Home/components';
import AccountPage from 'features/AccountPage/components';
import SuccessPage from 'features/Purchase/components/SuccessPage';
import B2BActivation from 'features/B2BActivation/components';

import PageNotFound from 'components/PageNotFound';

import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import { ROUTES } from './constants';
import hideable from 'utils/hideable';

const Navigation = () => (
  <Routes>
    {/* PUBLIC ROUTES */}

    <Route element={<UnauthenticatedRoute />}>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route
        path={ROUTES.FACEBOOK_LOGIN}
        element={<FacebookSignInContainer />}
      />
      <Route path={ROUTES.GOOGLE_LOGIN} element={<GoogleSignInContainer />} />
      <Route path={ROUTES.REGISTRATION} element={<Registration />} />
      <Route path={ROUTES.VERIFY_ACCOUNT} element={<AccountVerification />} />
      <Route
        path={ROUTES.SEND_PASSWORD_RESET_EMAIL}
        element={<SendPasswordResetEmail />}
      />
      <Route path={ROUTES.RESET_PASSWORD} element={<PasswordReset />} />
      <Route path={ROUTES.ACTIVATE_LICENSE} element={<B2BActivation />} />
    </Route>

    {/* PRIVATE ROUTES */}

    <Route path={ROUTES.MAIN} element={<AuthenticatedRoute />}>
      <Route index element={<Home />} />
      <Route path={`${ROUTES.ACCOUNT}/*`} element={<AccountPage />} />
      <Route path={ROUTES.PAYMENT_SUCCESS} element={<SuccessPage />} />
    </Route>

    {/* 404 */}

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default hideable(Navigation);
