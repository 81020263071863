import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as Checkmark } from 'assets/checkmark.svg';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { BREAKPOINTS } from 'theme/constants';
import { setSelectedPlan } from '../actions';
import {
  getPlanNameByUid,
  getPlanDescriptionByUid,
  getSelectedPlan,
  getPlanMonthlyPriceByUid,
  getPlanDiscountByUid,
} from '../selectors';

interface IOwnProps {
  uid: string;
}

interface IMapStateToProps {
  name: string;
  description: string;
  numberInteger: string;
  numberDecimalValue: string;
  discount: number;
}

interface IMapDispatchToProps {
  onClick: () => void;
}

interface ISelectedProp {
  isSelected: boolean;
}

type TMapStateToProps = IMapStateToProps & ISelectedProp;

type IPlanComponent = TMapStateToProps & IMapDispatchToProps;

const Card = styled.div<ISelectedProp>((props) => ({
  cursor: 'pointer',
  position: 'relative',
  width: 470,
  margin: 31,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: props.isSelected ? `1px solid ${props.theme.colors.orange}` : 'none',
  background: props.isSelected
    ? 'linear-gradient(110.99deg, #0C3965 12.38%, #0E2B57 29.92%, #0F2652 49.04%, #0F2652 63.03%, #172B58 75.15%, #283564 87.71%)'
    : props.theme.colors.blurryBlue,
  borderRadius: 5,
  [BREAKPOINTS.XL_MOBILE]: {
    width: '100%',
  },
}));

const PlanName = styled(Text)({
  fontSize: 24,
  marginTop: 40,
  marginBottom: 24,
});

const Description = styled(Text)({
  fontSize: 15,
  marginTop: 16,
  marginBottom: 40,
  padding: '0 60px',
  opacity: 0.5,
  textAlign: 'center',
});

const StyledCheckmark = styled(Checkmark)({
  position: 'absolute',
  top: 20,
  left: 20,
});

const PriceContainer = styled(Text)({
  fontSize: 24,
});

const PriceBig = styled.span({
  fontSize: 60,
  lineHeight: '68px',
});

const Dollar = styled.span({
  verticalAlign: 'top',
  marginRight: 5,
});

const DiscountContainer = styled.div((props) => ({
  position: 'absolute',
  top: -12,
  right: 40,
  backgroundColor: props.theme.colors.orange,
  padding: '2px 12px',
  borderRadius: 20,
}));

const PlanComponent: React.FC<IPlanComponent> = ({
  name,
  description,
  isSelected,
  numberInteger,
  numberDecimalValue,
  discount,
  onClick,
}) => (
  <Card isSelected={isSelected} onClick={onClick}>
    {discount ? (
      <DiscountContainer>
        <Text>Save {discount}%</Text>
      </DiscountContainer>
    ) : null}
    {isSelected && <StyledCheckmark />}
    <PlanName>{name}</PlanName>
    <PriceContainer>
      <Dollar>&#36;</Dollar>
      <PriceBig>{numberInteger}</PriceBig>.{numberDecimalValue}/mo
    </PriceContainer>
    <Description>{description}</Description>
  </Card>
);

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => {
  const splitPrice = getPlanMonthlyPriceByUid(state, uid)
    ?.toString()
    ?.split('.');

  const numberInteger = splitPrice?.[0];
  const numberDecimalValue = splitPrice?.[1] || '00';

  return {
    name: getPlanNameByUid(state, uid),
    description: getPlanDescriptionByUid(state, uid),
    isSelected: getSelectedPlan(state) === uid,
    discount: getPlanDiscountByUid(state, uid),
    numberInteger,
    numberDecimalValue,
  };
};

const mapDispatchToProps = (dispatch: TDispatch, { uid }: IOwnProps) => ({
  onClick: () => dispatch(setSelectedPlan(uid)),
});

export default connect<
  TMapStateToProps,
  IMapDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(PlanComponent);
