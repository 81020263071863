import styled from '@emotion/styled';

import { EXTERNAL_LINKS } from 'utils/constants';

const Container = styled.div({
  fontSize: 12,
  lineHeight: '16px',
  color: 'rgba(255, 255, 255, 0.5)',
  textAlign: 'center',
  marginTop: 24,
});

const LicenseLink = styled.a({
  color: 'rgba(255, 255, 255, 0.7)',
});

const LegalText: React.FC = () => (
  <Container>
    By creating Yours account you are agreeing to the{' '}
    <LicenseLink href={EXTERNAL_LINKS.PRIVACY_POLICY} target="_blank">
      Privacy Policy
    </LicenseLink>{' '}
    and{' '}
    <LicenseLink href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS} target="_blank">
      Terms & Conditions
    </LicenseLink>
  </Container>
);

export default LegalText;
