import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { updateSimpleValue } from 'utils/reducerHelpers';
import { IAccountDetails } from './types';
import {
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_ERROR,
  SET_OLD_PASSWORD,
  SET_OLD_PASSWORD_ERROR,
  RESET_NEW_PASSWORD_STATE,
  SET_PASSWORD_CHANGE_LOADING,
} from './actionTypes';

const newPasswordReducer = handleAction<string, string>(
  SET_NEW_PASSWORD,
  updateSimpleValue,
  ''
);

const oldPasswordReducer = handleAction<string, string>(
  SET_OLD_PASSWORD,
  updateSimpleValue,
  ''
);

const newPasswordErrorReducer = handleAction<string, string>(
  SET_NEW_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const oldPasswordErrorReducer = handleAction<string, string>(
  SET_OLD_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const passwordChangeLoadingReducer = handleAction<boolean, boolean>(
  SET_PASSWORD_CHANGE_LOADING,
  updateSimpleValue,
  false
);

const accountDetailsReducer = combineReducers<IAccountDetails>({
  newPassword: newPasswordReducer,
  newPasswordError: newPasswordErrorReducer,
  oldPassword: oldPasswordReducer,
  oldPasswordError: oldPasswordErrorReducer,
  isLoading: passwordChangeLoadingReducer,
});

export default resetReducer(RESET_NEW_PASSWORD_STATE, accountDetailsReducer);
