import { createAction } from 'redux-actions';
import { AxiosError } from 'axios';

import { TThunk } from 'types/common';
import { loginRequest } from 'features/Login/actions';
import { callCoreApi, API_URLS, METHODS } from 'api';
import { isEmailValid, isPasswordValid } from 'utils/helpers';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { ERROR_TEXTS } from 'utils/constants';
import { COOKIE_KEY, getCookie } from 'utils/cookies';
import { IMarketingProps } from 'features/Login/types';

import { IRegistrationApiProps } from './types';
import { getEmail, getPassword, isLoading, getFirstName } from './selectors';
import {
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_LOADING,
  SET_PASSWORD,
  RESET_STATE,
  SET_EMAIL_ERROR,
  SET_PASSWORD_ERROR,
  SET_FIRST_NAME_ERROR,
  SET_COMMON_ERROR,
} from './actionTypes';

export const resetState = createAction(RESET_STATE);
export const setEmail = createAction<string>(SET_EMAIL);
export const setFirstName = createAction<string>(SET_FIRST_NAME);
export const setPassword = createAction<string>(SET_PASSWORD);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setEmailError = createAction<string>(SET_EMAIL_ERROR);
export const setPasswordError = createAction<string>(SET_PASSWORD_ERROR);
export const setFirstNameError = createAction<string>(SET_FIRST_NAME_ERROR);
export const setCommonError = createAction<string>(SET_COMMON_ERROR);

export const handleRegistration = (): TThunk => (dispatch, getState) => {
  const state = getState();

  let areValidationErrors;

  if (isLoading(state)) {
    return;
  }

  dispatch(setEmailError(''));
  dispatch(setFirstNameError(''));
  dispatch(setPasswordError(''));

  const email = getEmail(state);
  const firstName = getFirstName(state);
  const password = getPassword(state);

  if (!isEmailValid(email)) {
    dispatch(setEmailError(ERROR_TEXTS.VALID_EMAIL));
    areValidationErrors = true;
  }

  if (!firstName) {
    dispatch(setFirstNameError(ERROR_TEXTS.BLANK_FIELD));
    areValidationErrors = true;
  }

  if (!isPasswordValid(password)) {
    dispatch(setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR));
    areValidationErrors = true;
  }

  if (areValidationErrors) {
    return;
  }

  dispatch(handleRegistrationRequest(firstName, email, password));
};

export const handleRegistrationRequest =
  (firstName: string, email: string, password: string): TThunk =>
  (dispatch) => {
    dispatch(setLoading(true));

    const fbp = getCookie(COOKIE_KEY._FBP);
    const fbc = getCookie(COOKIE_KEY._FBC);
    const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
    const fbclid = getCookie(COOKIE_KEY.FBCLID);
    const gclid = getCookie(COOKIE_KEY.GCLID);
    const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID);
    const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID);
    const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN);
    const source = getCookie(COOKIE_KEY.UTM_SOURCE);
    const medium = getCookie(COOKIE_KEY.UTM_MEDIUM);
    const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID);
    let google_cid;

    if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
      google_cid = window.ga?.getAll()?.[0]?.get('clientId');
    }

    callCoreApi<IRegistrationApiProps & IMarketingProps>({
      url: API_URLS.REGISTER,
      method: METHODS.POST,
      data: {
        email,
        password,
        first_name: firstName,
        google_cid,
        appsflyer_web_id,
        platform: 'web',
        fbp,
        fbc,
        gclid,
        fbclid,
        tune_offer_id,
        tune_transaction_id,
        campaign,
        source,
        medium,
        impact_click_id,
      },
    })
      .then(() => {
        dispatch(loginRequest(email, password));
      })
      .catch((error: AxiosError) => {
        dispatch(setLoading(false));

        if (
          error.response?.data?.custom_status_code ===
          CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
        ) {
          const passwordError =
            error.response?.data?.errors?.password?.[0] || '';
          const emailError = error.response?.data?.errors?.email?.[0] || '';
          const firstNameError =
            error.response?.data?.errors?.fist_name?.[0] || '';

          dispatch(setFirstNameError(firstNameError));
          dispatch(setPasswordError(passwordError));
          dispatch(setEmailError(emailError));
        } else {
          dispatch(setCommonError(ERROR_TEXTS.SOMETHING_WENT_WRONG));
        }
      });
  };
