import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const isInvalidToken = (state: IReduxState) =>
  state?.[NAME]?.isInvalidToken;

export const isVerificationSuccessful = (state: IReduxState) =>
  state?.[NAME]?.isVerificationSuccessful;

export const isResendSuccess = (state: IReduxState) =>
  state?.[NAME]?.isResendSuccess;

export const isVerificationLoading = (state: IReduxState) =>
  state?.[NAME]?.isVerificationLoading;

export const isResendVisible = (state: IReduxState) =>
  state?.[NAME]?.isResendVisible;

export const isResendLoading = (state: IReduxState) =>
  state?.[NAME]?.isResendLoading;
