import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';

import { TThunk } from 'types/common';
import { callCoreApi, METHODS, API_URLS } from 'api';
import { getQueryParam } from 'utils/helpers';
import { ERROR_TEXTS, QUERY_PARAMS } from 'utils/constants';
import { IVerifyAccountProps, IResendTokenProps } from './types';
import { getEmail } from './selectors';
import {
  SET_EMAIL,
  SET_INVALID_TOKEN,
  SET_VERIFY_SUCCESS,
  SET_VERIFICATION_LOADING,
  SET_RESEND_VISIBILE,
  RESET_STATE,
  SET_RESEND_LOADING,
  SET_RESEND_SUCCESS,
} from './actionTypes';

export const resetState = createAction(RESET_STATE);
export const setVerifySuccess = createAction(SET_VERIFY_SUCCESS);
export const setResendSuccess = createAction(SET_RESEND_SUCCESS);
export const setInvalidToken = createAction<boolean>(SET_INVALID_TOKEN);
export const setResendVisible = createAction<boolean>(SET_RESEND_VISIBILE);
export const setEmail = createAction<string>(SET_EMAIL);
export const setResendLoading = createAction<boolean>(SET_RESEND_LOADING);
export const setVerificationLoading = createAction<boolean>(
  SET_VERIFICATION_LOADING
);

export const handleAccountVerification = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const token = getQueryParam(state, QUERY_PARAMS.TOKEN);

  if (!token) {
    dispatch(setResendVisible(true));
    return;
  }

  dispatch(setVerificationLoading(true));

  callCoreApi<IVerifyAccountProps>({
    method: METHODS.POST,
    url: API_URLS.VERIFY_ACCOUNT,
    data: { token },
  })
    .then(() => {
      dispatch(setVerifySuccess());
    })
    .catch(() => {
      dispatch(setInvalidToken(true));
      dispatch(setResendVisible(true));
    })
    .finally(() => {
      dispatch(setVerificationLoading(false));
    });
};

export const handleResend = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const email = getEmail(state);

  dispatch(setResendLoading(true));
  dispatch(setInvalidToken(false));

  callCoreApi<IResendTokenProps>({
    method: METHODS.POST,
    url: API_URLS.RESEND_VERIFICATION_TOKEN,
    data: { email },
  })
    .then(() => {
      dispatch(setResendSuccess());
    })
    .catch(() => {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
    })
    .finally(() => {
      dispatch(setResendLoading(false));
    });
};
