import { KeyboardEventHandler, useCallback } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { IReduxState } from 'state/types';
import { setPassword, resetPassword } from '../actions';
import {
  isLoading,
  getPassword,
  isFormVisible,
  getPasswordError,
} from '../selectors';

interface IMapStateToProps {
  isButtonDisabled: boolean;
  password: string;
  passwordError: string;
}

interface IMapDispatchToProps {
  onClick: () => void;
  onChange: (value: string) => void;
}

type TResetPassword = IMapStateToProps & IMapDispatchToProps;

const Title = styled(Text)({
  marginTop: '40px',
});

const StyledInput = styled(Input)({
  marginBottom: '32px',
  marginTop: '32px',
});

const ResetPassword: React.FC<TResetPassword> = ({
  isButtonDisabled,
  password,
  passwordError,
  onChange,
  onClick,
}) => {
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (!isButtonDisabled) onClick();
      }
    },
    [isButtonDisabled, onClick]
  );
  return (
    <>
      <Title type="h2">Please write your new password.</Title>
      <StyledInput
        error={passwordError}
        value={password}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        type="password"
      />
      <Button
        text="Reset password"
        disabled={isButtonDisabled}
        onClick={onClick}
      />
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isButtonDisabled: isLoading(state),
  password: getPassword(state),
  passwordError: getPasswordError(state),
  isVisible: isFormVisible(state),
});

const mapDispatchToProps = {
  onChange: setPassword,
  onClick: resetPassword,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable<TResetPassword>(ResetPassword));
