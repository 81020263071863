import { createSelector } from 'reselect';

import { NAME } from './constants';
import { PAYMENT_PROVIDERS, TSubscriptionStatus } from './types';

import { IReduxState } from 'state/types';

export const getSubscriptionName = (state: IReduxState) => state?.[NAME]?.name;

export const getSubscriptionId = (state: IReduxState) =>
  state?.[NAME]?.subscriptionId;

export const getSubscriptionStatus = (state: IReduxState) =>
  state?.[NAME]?.subscriptionStatus;

export const getSubscriptionValidFromDate = (state: IReduxState) =>
  state?.[NAME]?.validFrom;

export const getSubscriptionValidToDate = (state: IReduxState) =>
  state?.[NAME]?.validTo;

export const userHasSubscription = (state: IReduxState) =>
  state?.[NAME]?.userHasSubscription;

export const getPaymentProvider = (state: IReduxState) =>
  state?.[NAME]?.paymentProvider;

export const isAutoRenewEnabled = (state: IReduxState) =>
  state?.[NAME]?.isAutoRenewEnabled;

export const isAutoRenewLoading = (state: IReduxState) =>
  state?.[NAME]?.isAutoRenewLoading;

export const isUpdatePaymentDetailsLoading = (state: IReduxState) =>
  state?.[NAME]?.isUpdatePaymentDetailsLoading;

export const getProviderProductId = (state: IReduxState) =>
  state?.[NAME]?.providerProductId;

export const getSubscriptionManagementText = createSelector(
  getPaymentProvider,
  (paymentProvider: PAYMENT_PROVIDERS | null) => {
    switch (paymentProvider) {
      case PAYMENT_PROVIDERS.APPLE:
        return 'You can manage your subscription in Apple subscriptions panel.';
      case PAYMENT_PROVIDERS.GOOGLE:
        return 'You can manage your subscription in Google subscriptions panel.';
      default:
        return '';
    }
  }
);

export const shouldShowRenewalManagementButton = createSelector(
  getPaymentProvider,
  getProviderProductId,
  isAutoRenewEnabled,
  (
    paymentProvider: PAYMENT_PROVIDERS | null,
    providerProductId: string,
    autoRenewEnabled
  ) => {
    const isB2BPlan =
      providerProductId === process.env.REACT_APP_STRIPE_BUSINESS_PLAN_ID;

    if (
      paymentProvider === PAYMENT_PROVIDERS.PAYPAL &&
      autoRenewEnabled &&
      !isB2BPlan
    ) {
      return true;
    }

    if (paymentProvider === PAYMENT_PROVIDERS.STRIPE && !isB2BPlan) {
      return true;
    }

    return false;
  }
);

export const shouldShowUpdatePaymentDetailsButton = createSelector(
  getPaymentProvider,
  getSubscriptionStatus,
  getProviderProductId,
  (
    paymentProvider: PAYMENT_PROVIDERS | null,
    status: TSubscriptionStatus | null,
    providerProductId: string
  ) => {
    const isB2BPlan =
      providerProductId === process.env.REACT_APP_STRIPE_BUSINESS_PLAN_ID;

    return (
      paymentProvider === PAYMENT_PROVIDERS.STRIPE &&
      status === 'active' &&
      !isB2BPlan
    );
  }
);
