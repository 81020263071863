import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    darkGray: '#3F5561',
    lightGray: '#8B929D',
    electric: '#0196AA',
    electricDark: '#017E8E',
    green: '#0BAE88',
    darkGreen: '#016674',
    white: '#FFFFFF',
    error: '#E83D32',
    errorBackground: 'rgba(232, 61, 50, 0.3)',
    blue: '#182F40',
    lightBlue: '#396CD8',
    darkBlue: '#2A519E',
    facebookBlue: '#1877F2',
    facebookBlueHover: '#004CBE',
    black: '#0F1E27',
    darkBlueBackground: '#0F2652',
    backgroundDark: '#061218',
    orange: '#E5830E',
    shinyBlue: '#3058BE',
    blurryBlue: '#173164',
    menuBlue: '#2D54B4',
    accountPageGray: '#152430',
  },
};

export default theme;
