import { createAction } from 'redux-actions';
import { AxiosError } from 'axios';

import { callCoreApi, METHODS, API_URLS } from 'api';
import { TThunk } from 'types/common';
import { getEmail } from './selectors';
import { ISendPasswordResetEmailApiProps } from './types';
import { isEmailValid } from 'utils/helpers';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { ERROR_TEXTS } from 'utils/constants';
import {
  SET_EMAIL,
  SET_LOADING,
  SET_SUCCESS,
  SET_EMAIL_ERROR,
  SET_RESEND_FLOW,
} from './actionTypes';

export const setEmail = createAction<string>(SET_EMAIL);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setSuccess = createAction<boolean>(SET_SUCCESS);
export const setResendFlow = createAction<boolean>(SET_RESEND_FLOW);
export const setEmailError = createAction<string>(SET_EMAIL_ERROR);

export const sendEmail = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const email = getEmail(state);

  dispatch(setEmailError(''));

  if (!isEmailValid(email)) {
    dispatch(setEmailError(ERROR_TEXTS.VALID_EMAIL));

    return;
  }

  dispatch(setLoading(true));

  callCoreApi<ISendPasswordResetEmailApiProps>({
    method: METHODS.POST,
    url: API_URLS.SEND_PASSWORD_RESET_EMAIL,
    data: { email },
  })
    .then(() => {
      dispatch(setSuccess(true));
    })
    .catch((error: AxiosError) => {
      if (
        error.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
      ) {
        const emailError = error.response?.data?.errors?.email?.[0] || '';

        dispatch(setEmailError(emailError));
      } else {
        dispatch(setEmailError(ERROR_TEXTS.SOMETHING_WENT_WRONG));
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
