import { NAME } from './constants';

export const RESET_STATE = `${NAME}.RESET_STATE`;
export const SET_VERIFY_SUCCESS = `${NAME}.SET_VERIFY_SUCCESS`;
export const SET_RESEND_SUCCESS = `${NAME}.SET_RESEND_SUCCESS`;
export const SET_INVALID_TOKEN = `${NAME}.SET_INVALID_TOKEN`;
export const SET_RESEND_VISIBILE = `${NAME}.SET_RESEND_VISIBILE`;
export const SET_VERIFICATION_LOADING = `${NAME}.SET_VERIFICATION_LOADING`;
export const SET_RESEND_LOADING = `${NAME}.SET_RESEND_LOADING`;
export const SET_EMAIL = `${NAME}.SET_EMAIL`;
