import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Text from 'components/Text';
import { ReactComponent as SuccessIcon } from 'assets/pass_change_key.svg';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { IReduxState } from 'state/types';
import { isSuccess } from '../selectors';
import { TDispatch } from 'types/common';
import { ROUTES } from 'navigation/constants';
import Button from 'components/Button';

interface IMapDispatchToProps {
  onClick: () => void;
}

const StyledIcon = styled(SuccessIcon)({
  marginTop: '100px',
  marginBottom: '32px',
});

const StyledNote = styled(Text)({
  opacity: '80%',
  marginTop: '32px',
  marginBottom: '32px',
});

const PasswordResetSuccess: React.FC<IMapDispatchToProps> = ({ onClick }) => (
  <>
    <StyledIcon />
    <Text type="h1">Successfully changed</Text>
    <StyledNote align="center">
      Congrats! Your password was successfully changed
    </StyledNote>
    <Button text="Log in" onClick={onClick} />
  </>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: isSuccess(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(push(ROUTES.LOGIN)),
});

export default connect<
  TWithHideableProp<unknown>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(PasswordResetSuccess));
