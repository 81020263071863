import styled from '@emotion/styled';

type TInputType = 'password' | 'email';

interface ISignInInput {
  onChange: (value: string) => void;
  value: string;
  type?: TInputType;
  placeholder?: string;
  icon: React.ReactNode;
}

interface IStyledInputProps {
  isError: boolean;
}

type TSignInInput = ISignInInput & IStyledInputProps;

const Container = styled.div<IStyledInputProps>(({ theme, isError }) => ({
  display: 'flex',
  backgroundColor: isError
    ? theme.colors.errorBackground
    : theme.colors.darkGray,
  borderRadius: '100px',
  border: isError ? `1px solid ${theme.colors.error}` : 'none',
  height: '42px',
  marginBottom: '8px',
  marginTop: '8px',
  alignItems: 'center',
  width: '100%',
}));

const IconContainer = styled.div({
  marginLeft: '25px',
  marginRight: '5px',
  display: 'flex',
  alignItems: 'center',
});

const Input = styled.input(({ theme }) => ({
  border: 'none',
  background: 'none',
  width: '100%',
  outline: 'none',
  fontSize: '15px',
  color: theme.colors.white,
  marginLeft: '5px',
  '::placeholder': {
    color: theme.colors.lightGray,
  },
}));

const SignInInput: React.FC<TSignInInput> = ({
  onChange,
  value,
  type,
  placeholder,
  icon,
  isError,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Container isError={isError} {...rest}>
      <IconContainer>{icon}</IconContainer>
      <Input
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        type={type}
      />
    </Container>
  );
};

export default SignInInput;
