import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { TThunk } from 'types/common';
import { IChangePasswordBody } from './types';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { ERROR_TEXTS } from 'utils/constants';
import {
  getNewPassword,
  getOldPassword,
  isPasswordChangeLoading,
} from './selectors';
import {
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_ERROR,
  SET_OLD_PASSWORD,
  SET_OLD_PASSWORD_ERROR,
  RESET_NEW_PASSWORD_STATE,
  SET_PASSWORD_CHANGE_LOADING,
} from './actionTypes';

export const setNewPassword = createAction<string>(SET_NEW_PASSWORD);
export const setNewPasswordError = createAction<string>(SET_NEW_PASSWORD_ERROR);
export const setOldPassword = createAction<string>(SET_OLD_PASSWORD);
export const setOldPasswordError = createAction<string>(SET_OLD_PASSWORD_ERROR);
export const resetNewPasswordState = createAction(RESET_NEW_PASSWORD_STATE);
export const setPasswordChangeLoading = createAction<boolean>(
  SET_PASSWORD_CHANGE_LOADING
);

export const changePassword = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const oldPassword = getOldPassword(state);
  const newPassword = getNewPassword(state);
  const isLoading = isPasswordChangeLoading(state);

  if (isLoading) {
    return;
  }

  if (!oldPassword) {
    dispatch(setOldPasswordError('Old password is required.'));

    return;
  }

  if (!newPassword) {
    dispatch(setNewPasswordError('New password is required.'));

    return;
  }

  dispatch(setOldPasswordError(''));
  dispatch(setNewPasswordError(''));
  dispatch(setPasswordChangeLoading(true));

  callCoreApi<IChangePasswordBody>({
    method: METHODS.POST,
    url: API_URLS.CHANGE_PASSWORD,
    authorized: true,
    data: {
      password: oldPassword,
      new_password: newPassword,
    },
  })
    .then(() => {
      dispatch(resetNewPasswordState());
      toast.success('Password has been changed successfully.');
    })
    .catch((error: AxiosError) => {
      if (
        error.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
      ) {
        const oldPasswordError =
          error?.response?.data?.errors?.password?.[0] || '';
        const newPasswordError =
          error?.response?.data?.errors?.new_password?.[0] || '';

        dispatch(setOldPasswordError(oldPasswordError));
        dispatch(setNewPasswordError(newPasswordError));
      } else {
        toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      }
    })
    .finally(() => {
      dispatch(setPasswordChangeLoading(false));
    });
};
