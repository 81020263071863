import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { IByUid } from 'types/common';
import { addByUid, addUids, updateSimpleValue } from 'utils/reducerHelpers';
import { TFormattedPlan, IPurchase } from './types';
import {
  SET_PLANS,
  SET_SELECTED_PLAN,
  SET_LOADING,
  SET_AUTO_PURCHASE_LOADING,
} from './actionTypes';

const plansUidsReducer = handleAction<string[], TFormattedPlan[]>(
  SET_PLANS,
  addUids,
  []
);

const plansByUidReducer = handleAction<
  IByUid<TFormattedPlan>,
  TFormattedPlan[]
>(SET_PLANS, addByUid, {});

const selectedPlanReducer = handleAction<string, string>(
  SET_SELECTED_PLAN,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const isAutoPurchaseLoading = handleAction<boolean, boolean>(
  SET_AUTO_PURCHASE_LOADING,
  updateSimpleValue,
  false
);

export default combineReducers<IPurchase>({
  plansUids: plansUidsReducer,
  plansByUid: plansByUidReducer,
  selectedPlan: selectedPlanReducer,
  isLoading: isLoadingReducer,
  isAutoPurchaseLoading: isAutoPurchaseLoading,
});
