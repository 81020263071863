import styled from '@emotion/styled';

const FullScreenWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
});

export default FullScreenWrapper;
