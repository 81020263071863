import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const getPassword = (state: IReduxState) => state?.[NAME]?.password;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const isEmailError = (state: IReduxState) => state?.[NAME]?.isEmailError;

export const getErrorText = (state: IReduxState) => state?.[NAME]?.errorText;

export const isPasswordError = (state: IReduxState) =>
  state?.[NAME]?.isPasswordError;
